import React from 'react';
import Slider from 'react-slick';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import './index.css';
import { Box } from '@mui/material';

// Type for image
interface ImageProps {
  src: string;
  alt: string;
}

interface ImageCarouselProps {
  images: ImageProps[];
}

// Custom arrow components for slick navigation
const NextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className='absolute top-1/2 right-6 transform -translate-y-1/2 z-10'
    >
      <ArrowForwardIos
        fontSize='small'
        sx={{color:'#93897F'}}
      />
    </button>
  );
};

const PrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className='absolute top-1/2 left-6 transform -translate-y-1/2 z-10'
    >
      <ArrowBackIos
        fontSize='small'
        sx={{color:'#93897F'}}
      />
    </button>
  );
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centered: true,
    customPaging: (i: number) => (
      <Box className='w-12 h-12 rounded-md overflow-hidden'>
        <img
          src={images[i].src}
          alt={images[i].alt}
          className='object-cover w-full h-full'
        />
      </Box>
    ),
    dotsClass: 'slick-dots custom-dots',
  };

  return (
    <div className='w-[300px] md:w-[648px]'>
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            className='w-[300px] md:w-[365px] h-[365px] bg-secondary-400 justify-center items-center !flex rounded-t-lg rounded-rt-lg'
          >
            <img
              src={image.src}
              alt={image.alt}
              className='w-full h-full max-w-[300px] max-h-[300px]'
            />
          </Box>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
