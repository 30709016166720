import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { Provider } from 'react-redux';
import theme from './assets/theme';
import { ThemeProvider } from '@emotion/react';
import { SnackbarProvider } from './components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
      <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
);

reportWebVitals();
