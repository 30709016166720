import React, { Fragment } from 'react';
import { Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import { useNavigate } from 'react-router-dom';

interface ProductListItemProps {
  title: string;
  description: string;
  location: string;
  visibility: string;
  date: string;
  imageUrl: string;
  onEdit?: () => void;
  onDelete?: () => void;
  showDate?: boolean;
  customActions?: React.ReactNode;
  showLocation?: boolean;
  showVisibility?: boolean;
  showActions?: boolean;
  showClaimButton?: boolean;
}

export const ProductListItem: React.FC<ProductListItemProps> = ({
  title,
  description,
  location,
  visibility,
  date,
  imageUrl,
  onEdit,
  onDelete,
  customActions,
  showDate = true,
  showLocation = true,
  showVisibility = true,
  showActions = true,
  showClaimButton = false,
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const navigate = useNavigate();

  return (
    <div className='flex items-center justify-center p-2 bg-[#F7EFDE] rounded-xl w-full min-w-[353px]'>
      {/* Image */}
      <div className='w-14 h-14 flex-shrink-0 rounded-md'>
        <img
          src={imageUrl}
          alt={title}
          className='w-full h-full object-cover rounded-md'
        />
      </div>

      {/* Content */}
      <div className='flex flex-col flex-grow ml-4'>
        <Typography
          component='p'
          color='#4A403A'
          fontSize='14px'
          fontWeight={500}
        >
          {title}
        </Typography>
        <div className='flex items-center justify-between'>
          <Typography
            component='p'
            fontSize='12px'
            letterSpacing='-0.14px'
            color='#655B53FA'
          >
            {description}
          </Typography>
          {showActions && (
            <div className='flex items-center ml-4 space-x-2'>
              {customActions ? (
                customActions
              ) : (
                <>
                  <Tooltip title='Edit'>
                    <EditIcon
                      sx={{ fontSize: '16px' }}
                      onClick={onEdit}
                    />
                  </Tooltip>

                  <Tooltip title='Delete'>
                    <DeleteIcon
                      sx={{ fontSize: '16px' }}
                      color='error'
                      onClick={onDelete}
                    />
                  </Tooltip>

                  <MoreVertIcon sx={{ fontSize: '16px' }} />
                </>
              )}
            </div>
          )}
        </div>

        {/* Conditionally render Location */}
        <div className='flex items-center text-sm'>
          {showLocation && (
            <Fragment>
              <LocationOnIcon
                className='me-1'
                sx={{
                  color: '#776E65',
                  fontSize: '16px',
                }}
              />
              <Typography
                component='span'
                fontSize='12px'
                className='mx-1'
                color='#776E65'
              >
                {location}
              </Typography>
              {showVisibility && (
                <Fragment>
                  <PublicIcon
                    className='ms-4 me-2'
                    sx={{
                      color: '#776E65',
                      fontSize: '16px',
                    }}
                  />
                  <Typography
                    component='span'
                    fontSize='12px'
                    className='ml-1'
                    color='#776E65'
                  >
                    {visibility}
                  </Typography>
                </Fragment>
              )}
              {/* Date */}
              {showDate && (
                <Fragment>
                  <AccessTimeFilledIcon
                    className='ms-4 me-2'
                    sx={{
                      color: '#776E65',
                      fontSize: '16px',
                    }}
                  />
                  <Typography
                    component='span'
                    fontSize='10px'
                    color='#776E65'
                  >
                    {date}
                  </Typography>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {showClaimButton && (
        <Button
          onClick={() => {
            navigate('/claim-item');
          }}
        >
          {staticTexts.claimItem}
        </Button>
      )}
    </div>
  );
};
