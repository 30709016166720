import { SET_LANGUAGE, GET_LANGUAGE } from '../../actions';
import { SET_VIEW } from '../../actions/my-items';

export const initialState = localStorage.getItem('lang') || 'ar';

export const languageReducer = (state = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_LANGUAGE: 
      return action.payload;
    default: 
      return state;
    
  }
};
