import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { styled } from '@mui/material/styles';
import { Dialog } from '../Dialog';
import { GoogleMap } from '../GoogleMap';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';

interface LocationInputProps {
  name: string;
  control: any;
  label?: string;
  onDialogToggle: (isOpen: boolean) => void;
  onCurrentLocationClick: (location: { latitude: number; longitude: number } | null, error: string | null) => void;
  handleLocationSelect: (location: { lat: number; lng: number; description: string }) => void;
  rules?: Record<string, any>;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[300],
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey[400],
  },
  '& .MuiInputAdornment-root': {
    color: theme.palette.grey[500],
  },
}));

export const LocationInput: React.FC<LocationInputProps> = ({
  name,
  control,
  label = 'Location',
  onDialogToggle,
  onCurrentLocationClick,
  handleLocationSelect,
  rules,
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number; description: string } | null>(
    null
  );

  const handleUseCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newLocation = { latitude, longitude };
          onCurrentLocationClick(newLocation, null);
        },
        (err) => {
          const errorMsg = `Error: ${err.message}`;
          onCurrentLocationClick(null, errorMsg);
        }
      );
    } else {
      const errorMsg = 'Geolocation is not supported by your browser.';
      onCurrentLocationClick(null, errorMsg);
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            {...field}
            fullWidth
            variant='outlined'
            label={label}
            error={!!error}
            helperText={error?.message || ' '}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <LocationOnIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <button
                    className='flex items-center space-x-1 text-accent text-sm'
                    type='button'
                  >
                    <span onClick={handleUseCurrentLocation}>{staticTexts.useCurrentLocation}</span>
                    <MyLocationIcon
                      fontSize='small'
                      className='text-accent'
                      onClick={() => setOpenDialog(true)}
                    />
                  </button>
                </InputAdornment>
              ),
              readOnly: true,
            }}
          />
        )}
      />

      <Dialog
        open={openDialog}
        content={
          <GoogleMap
            onLocationSelect={(location: { lat: number; lng: number; description: string }) => {
              handleLocationSelect(location);
              setOpenDialog(false);
            }}
          />
        }
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};
