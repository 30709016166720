import { createBrowserRouter } from 'react-router-dom';
import {
  HomePage,
  Login,
  PageNotFound,
  Signup,
  BusinessLogin,
  ItemDetail,
  Layout,
  Help,
  ManageMyItems,
  Profile,
  TermsAndConditions,
  PrivacyPolicy,
} from '../pages';
import { ResetPassword } from '../pages/reset-password';
import AddFirstItem from '../pages/my-items/add-first-item';
import { ReportLostItem } from '../pages/report-lost-item';
import { ReportFoundItem } from '../pages/report-found-item';
import ClaimItem from '../pages/claim-item';
import ClaimRequests from '../pages/business/claim-requests';
import { BusinessProfile } from '../pages/business/profile';
import OrganizationPage from '../pages/organization-page';
import SearchItems from '../pages/homepage/search-items';
import { Main } from '../pages/homepage/main';
import Chat from '../pages/chat';
import LoginWithPassword from '../pages/auth/login/password-login';
import { LoginWithOTP } from '../pages/auth/login/otp-login';
import ForgotPassword from '../pages/auth/forgot-password';
import { BusinessAddFirstItem, BusinessManageItems } from '../pages/business';
import { VerifySignupOTP } from '../pages/auth/verify-otp';
import { SearchMobile } from '../pages/homepage/search-items/mobile-view';

export default createBrowserRouter([
  {
    path: '',
    element: <Layout />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: 'auth',
        errorElement: <PageNotFound />,
        children: [
          {
            path: 'login',
            element: <Login />,
            errorElement: <PageNotFound />,
            children: [
              {
                path: 'password',
                element: <LoginWithPassword />,
                errorElement: <PageNotFound />,
              },
              {
                path: 'otp',
                element: <LoginWithOTP />,
                errorElement: <PageNotFound />,
              },
            ],
          },
          {
            path: 'signup',
            element: <Signup />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'verify-otp',
            element: <VerifySignupOTP />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'business-login',
            element: <BusinessLogin />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />,
            errorElement: <PageNotFound />,
          },
          {
            path: 'reset-password',
            element: <ResetPassword />,
            errorElement: <PageNotFound />,
          },
        ],
      },
      {
        path: 'home',
        element: <HomePage />,
        errorElement: <PageNotFound />,
        children: [
          {
            index: true,
            element: <Main />,
          },
          {
            path: 'search',
            element: <SearchItems />,
          },
        ],
      },
      {
        path: 'help',
        element: <Help />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'profile',
        element: <Profile />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'my-items',
        element: <ManageMyItems />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'add-first-item',
        element: <AddFirstItem />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'report-lost-item',
        element: <ReportLostItem />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'report-found-item',
        element: <ReportFoundItem />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'item-detail',
        element: <ItemDetail />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'business-manage-items',
        element: <BusinessManageItems />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'business-add-first-item',
        element: <BusinessAddFirstItem />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'claim-requests',
        element: <ClaimRequests />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'claim-item',
        element: <ClaimItem />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'business/profile',
        element: <BusinessProfile />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'chat',
        element: <Chat />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'terms-and-condition',
        element: <TermsAndConditions />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
        errorElement: <PageNotFound />,
      },
      {
        path: 'search-items',
        element: <SearchMobile />,
        errorElement: <PageNotFound />,
      },
    ],
  },
  {
    path: 'organization-page',
    element: <OrganizationPage />,
    errorElement: <PageNotFound />,
  }
]);
