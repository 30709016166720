import React from 'react';
import { Typography, Box, Grid, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import { arLqeetLogoForFooter, lqeetLogoForFooter } from '../../../assets';

/**
 * Author: Mayank
 * Date: 27 Nov 2024
 * Summary:
 * - This functional React component renders a footer section for the application.
 * - It uses Material-UI components for styling and layout.
 * Description:
 * - The footer is conditionally rendered based on the current route:
 *   - If the current route contains "auth" in its pathname, the footer is not displayed.
 *   - Otherwise, it displays a styled footer with three sections:
 *   1. **About Section**:
 *      - Displays the name of the platform ("Lqeet"), a brief description, and copyright information.
 *   2. **Quick Links Section**:
 *      - Provides navigation links for "About," "Help," "Terms & Conditions," and "Privacy Policy."
 *   3. **Lqeet Services Section**:
 *      - Offers links for services such as reporting found or lost items.
 */

export const Footer: React.FC = () => {
  const location = useLocation();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  if (location.pathname.includes('auth')) {
    return <></>;
  }

  return (
    <Box className='bg-[#FFF7E6] w-full flex justify-center items-center mt-8 min-w-96'>
      <Box
        width='100%'
        maxWidth='1200px'
      >
        <Box
          component='footer'
          className='p-6 border-t border-gray-200'
        >
          <Grid
            container
            spacing={3}
            justifyContent='space-between'
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <img
                className='mb-6'
                src={language === 'ar' ? arLqeetLogoForFooter : lqeetLogoForFooter}
                alt='logo'
              />
              <Typography
                variant='body1'
                color='textSecondary'
              >
                {staticTexts.aboutLqeet}
              </Typography>
              <Typography
                variant='caption'
                color='textSecondary'
                className='mt-4 block'
              >
                © 2024 Lqeet. B.V.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2}
            >
              <Typography
                variant='body2'
                className='font-semibold mb-2'
                fontSize='14px'
                marginBottom='28px'
              >
                {staticTexts.quickLinks}
              </Typography>
              <ul className='space-y-5 text-sm'>
                <li className='mb-5'>
                  <Link
                    href='#'
                    color='textSecondary'
                    underline='none'
                  >
                    {staticTexts.about}
                  </Link>
                </li>
                <li>
                  <Link
                    href='#'
                    color='textSecondary'
                    underline='none'
                  >
                    {staticTexts.help}
                  </Link>
                </li>
                <li>
                  <Link
                    href='#'
                    color='textSecondary'
                    underline='none'
                  >
                    {staticTexts.termsAndCondition}
                  </Link>
                </li>
                <li>
                  <Link
                    href='#'
                    color='textSecondary'
                    underline='none'
                  >
                    {staticTexts.privacyPolicy}
                  </Link>
                </li>
              </ul>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2}
            >
              <Typography
                variant='body2'
                className='font-semibold mb-2'
                fontSize='14px'
                marginBottom='28px'
              >
                {staticTexts.lqeetServices}
              </Typography>
              <ul className='space-y-5 text-sm'>
                <li>
                  <Link
                    href='#'
                    color='textSecondary'
                    underline='none'
                  >
                    {staticTexts.reportFoundItem}
                  </Link>
                </li>
                <li>
                  <Link
                    href='#'
                    color='textSecondary'
                    underline='none'
                  >
                    {staticTexts.reportLostItem}
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
