import React, { ChangeEvent, memo, useState } from 'react';
import { TextField, MenuItem, InputAdornment, Select, SelectChangeEvent } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import { countries } from '../../assets';
import { useSelector } from 'react-redux';

interface PhoneNumberInputProps {
  label?: string;
  className?: string;
  name: string;
  helperText?: string;
  countryCode?: string;
  phoneNumber?: string;
  control: Control<any>;
  error?: FieldError;
  helperTextColor?: string;
  validationRules?: Record<string, any>;
  handlePhoneNumberChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange?: (event: SelectChangeEvent<string>) => void;
}

const PhoneNumberInputComponent: React.FC<PhoneNumberInputProps> = ({
  label = 'Phone Number',
  className,
  name,
  helperText,
  countryCode = '+91',
  control,
  error,
  validationRules = {},
  helperTextColor,
}) => {
  const [dialCode, setDialCode] = useState(countryCode);
  const language = useSelector((state: any) => state.language);
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: `${label} is required`,
        ...validationRules,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          className={className}
          variant='outlined'
          fullWidth
          placeholder='00000 00000'
          size='small'
          error={!!error}
          helperText={error?.message || helperText}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, '');
            field.onChange(numericValue);
          }}
          sx={{
            '& .MuiFormHelperText-root': {
              color: error ? 'error.main' : helperTextColor || '#4A403A',
            },
          }}
          slotProps={{
            input: {
              inputMode: 'numeric',
              startAdornment: (
                <InputAdornment position='start'>
                  <Select
                    value={dialCode}
                    onChange={(e: any) => {
                      setDialCode(e.target.value);
                    }}
                    variant='standard'
                    disableUnderline
                    sx={{
                      marginRight: '8px',
                      '& .MuiSelect-select': {
                        paddingTop: '8px',
                        color: '#4A403A',
                        fontSize: '14px',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px',
                        },
                      },
                    }}
                  >
                    {countries.map((code) => (
                      <MenuItem
                        key={code.dial_code}
                        value={`+${code.dial_code}`}
                      >
                        {`+${code.dial_code}`}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            },
          }}
        />
      )}
    />
  );
};

export const PhoneNumberInput = memo(PhoneNumberInputComponent);
