import React from 'react';
import { Container, Typography, Box, Select, MenuItem } from '@mui/material';

export const Help = () => {
  return (
    <div className='text-secondary-200 min-h-screen'>
      <Container
        maxWidth='lg'
        className='py-16'
      >
        {/* Header Section */}
        <div className='flex justify-center items-center'>
          <Typography
            variant='h4'
            className='font-semibold text-center text-secondary-300'
          >
            Help & Support
          </Typography>
        </div>

        {/* Content Section */}
        <Box className='mt-8 space-y-8'>
          {/* How to Use */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-300 mb-4'
            >
              How to Use Lqeet?
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni, ullam.
            </Typography>
            <Typography className='text-lg leading-relaxed mt-2'>
              2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusantium, voluptate ut sit culpa
              quidem consequuntur ex praesentium dignissimos corporis.
            </Typography>
            <Typography className='text-lg leading-relaxed mt-2'>
              3. Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            </Typography>
          </Box>

          {/* FAQ */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-300 mb-4'
            >
              Frequently Asked Questions
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              <strong>Q: Lorem ipsum dolor sit amet ?</strong> <br />
              A: Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus blanditiis ea excepturi sapiente
              numquam vero, nesciunt dolores corrupti aliquam hic!
            </Typography>
            <Typography className='text-lg leading-relaxed mt-4'>
              <strong>Q: Lorem ipsum dolor sit amet consectetur adipisicing elit ?</strong> <br />
              A: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Necessitatibus
            </Typography>
            <Typography className='text-lg leading-relaxed mt-4'>
              <strong>Q: Lorem ipsum dolor sit amet consetetur, adipisicing elit. In, accusamus nostrum ?</strong>{' '}
              <br />
              A: Reach us at{' '}
              <a
                href='mailto:support@Lqeet.com'
                className='text-accent'
              >
                suppor@lqeet.com
              </a>
              .
            </Typography>
          </Box>

          {/* Contact Us */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-300 mb-4'
            >
              Need More Help?
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              If you have any questions or require further assistance, feel free to reach out to our support team at{' '}
              <a
                href='mailto:support@Lqeet.com'
                className='text-accent'
              >
                support@lqeet.com
              </a>
              .
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
