import { Search } from '@mui/icons-material';
import React from 'react';

interface SearchInputProps  {
  placeholderText: string;
}

export const SearchInput = ({placeholderText = 'Search'}: SearchInputProps) => {
    return (
      <div className="relative rounded-lg border border-[#C9C2B4]" >
        <input
          type="text"
          placeholder= {placeholderText}
          className="w-full ps-10 pr-4 py-2 rounded-lg border-none outline-none bg-[#FFF7E6]"
        />
        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#776E65]">
          <Search />
        </span>
      </div>
    );
  };
