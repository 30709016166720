import React, { Fragment, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getStaticTexts, ProfileIcon } from '../../../assets';
import { SearchInput } from '../../../components';
import { useSelector } from 'react-redux';

interface User {
  id: number;
  name: string;
  lastMessage: string;
  item: string;
  status: 'Online' | 'Offline';
  date: string;
}

interface ChatListProps {
  users: User[];
  onSelectUser: (user: User) => void;
  className?: string;
}

const ChatList: React.FC<ChatListProps> = ({ users, onSelectUser, className }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const language = useSelector((state:any)=> state.language);
  const staticTexts = getStaticTexts(language);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteChat = () => {
    // Your delete chat logic here
    console.log('Chat deleted');
    handleClose();
  };

  return (
    <div className={`${className} p-4 border-r border-gray-200`}>
      <Typography
        variant='h6'
        className='!mb-2'
      >
        {staticTexts.chats}
      </Typography>
      <SearchInput placeholderText='Search here' />
      <List>
        {users.map((user:any) => (
          <ListItem
            key={user.id}
            onClick={() => onSelectUser(user)}
            sx={{
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#FFE0B5',
                cursor: 'pointer',
              },
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ECE4D5',
              padding: '12px 16px',
           //   backgroundColor: user.selected ? '#FFE0B5' : 'transparent',
            }}
          >
            <Avatar
              src={ProfileIcon}
              sx={{
                marginRight: '12px',
                width: '40px',
                height: '40px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Box flex={1}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography
                  variant='body2'
                  color='#4A403A'
                  sx={{ fontSize: '14px', fontWeight: '500' }}
                >
                  {user.name}
                </Typography>
                <Typography
                  variant='body2'
                  color='#B5B3B0'
                  sx={{ fontSize: '12px' }}
                >
                  {user.date}
                </Typography>
              </Box>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography
                  variant='body2'
                  color='#6F6D6B'
                  sx={{
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {user.item}
                </Typography>
              </Box>
              <Typography
                variant='body2'
                color='#6F6D6B'
                sx={{
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {user.lastMessage}
              </Typography>
            </Box>
            <IconButton
              edge='end'
              disableRipple
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                sx={{
                  minWidth: '200px',
                }}
                onClick={handleDeleteChat}
              >
               Report user
              </MenuItem>
            </Menu>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ChatList;
