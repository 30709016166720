import React, { Fragment, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { CustomToggle, ProductListItem } from '../../../../components';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Search } from '@mui/icons-material';
import { iPhoneImg } from '../../../../assets';
import ViewListIcon from '@mui/icons-material/ViewListRounded';
import GridViewIcon from '@mui/icons-material/GridViewRounded';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useLocation } from 'react-router-dom';

const SearchInput = () => {
  return (
    <div className='relative w-1/2 rounded-lg border border-[#C9C2B4]'>
      <input
        type='text'
        placeholder='Search'
        className='w-full ps-10 pr-4 py-2 rounded-lg border-none outline-none bg-[#FFF7E6]'
      />
      <span className='absolute left-3 top-1/2 transform -translate-y-1/2 text-[#776E65]'>
        <Search />
      </span>
    </div>
  );
};

const Overview = (props: { title: string; description: string }) => {
  return (
    <Box>
      <Typography
        textAlign='left'
        component='p'
        fontSize='14px'
        letterSpacing='0px'
        color='#655B53FA'
      >
        {props.title}
      </Typography>
      <Typography
        textAlign='left'
        component='p'
        marginTop='24px'
        fontSize='26px'
        letterSpacing='0.01px'
        color='#FF6F61'
      >
        2,350
      </Typography>

      <Typography
        color='#776E65'
        fontSize='12px'
        marginTop='8px'
        textAlign='left'
        marginRight='8px'
      >
        {props.description}
      </Typography>
    </Box>
  );
};
export const BusinessManageItems = () => {
  const [selected, setSelected] = useState('Found Items');
  const location = useLocation();
  const { showOverview } = location.state || {};
  const [view, setView] = React.useState('List');
  const products = [
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'Iphone 6',
      description: 'New Apple iPhone 16 Pro, 256GB.',
      location: 'Mohali',
      date: '10 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'Samsung Galaxy',
      description: 'Samsung Galaxy S21, 128GB.',
      location: 'Chandigarh',
      date: '12 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Accessory',
      itemName: 'Black Leather Wallet',
      description: "Wallet with driver's license and cards.",
      location: 'Downtown Café, 5th Avenue',
      date: '13 Oct 2024',
      type: 'Public',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Accessory',
      itemName: 'Ray-Ban Sunglasses',
      description: 'Classic Ray-Ban sunglasses.',
      location: 'Park Mall',
      date: '15 Oct 2024',
      type: 'Public',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'AirPods Pro',
      description: 'Apple AirPods Pro, with case.',
      location: 'City Center',
      date: '17 Oct 2024',
      type: 'Private',
    },
  ];
  const handleToggle = (option: string) => {
    setSelected(option);
  };

  return (
    <Fragment>
      {showOverview && (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minWidth='384px'
      >
        <Typography
          textAlign='left'
          component='p'
          width='70%'
          marginTop='64px'
          marginBottom='40px'
          fontSize='26px'
          letterSpacing='0.01px'
          color='#4A403A'
        >
          Overview
        </Typography>

          <Box
            bgcolor='#FFF7E6'
            boxShadow='0px 1px 1px #0000000D'
            borderRadius='8px'
            paddingX='48px'
            paddingY='32px'
            display='flex'
            width='70%'
          >
            <Overview
              title='Total Found Items'
              description='The total number of items that have been found'
            />
            <Overview
              title='Total Resolved Items'
              description='The total number of items that have been resolved'
            />
            <Overview
              title='Active Listings'
              description='Items currently visible on the public for claimants to view and claim.'
            />
            <Overview
              title='Pending Claims'
              description='Items that have been requested for claim.'
            />
          </Box>
      </Box>
        )}

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <Typography
          textAlign='left'
          component='p'
          width='70%'
          marginTop='68px'
          fontSize='26px'
          letterSpacing='0.01px'
          color='#4A403A'
        >
          Manage Items
        </Typography>

        <Box
          marginTop='40px'
          width='70%'
          bgcolor='#FFF7E6'
          display='flex'
          justifyContent='start'
          alignItems='center'
          borderRadius='8px'
          flexDirection='column'
          padding='32px'
          boxShadow='0px 1px 1px #0000000D'
          overflow='scroll'
        >
          <CustomToggle
            selected={selected}
            options={[
              { key: 'Found Items', labelKey: 'foundItems' },
              { key: 'Resolved Items', labelKey: 'resolvedItems' },
            ]}
            handleToggle={handleToggle}
          />

          <Typography
            textAlign='left'
            component='p'
            className='w-full'
            marginTop='24px'
            fontSize='22px'
            letterSpacing='0.01px'
            color='#4A403A'
            marginBottom='16px'
          >
            {selected}
          </Typography>

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
          >
            <SearchInput />
            <Box
              display='flex'
              alignItems='center'
              width='fit-content'
            >
              <span className='text-secondary-200 text-sm me-4'>{200} lost items</span>

              <IconButton
                onClick={() => {}}
                className='border border-blue-500'
                size='small'
                disableRipple
                sx={{
                  marginInlineEnd: '16px',
                  height: 18,
                  '& .MuiSvgIcon-root': {
                    fontSize: 14,
                  },
                }}
              >
                {view === 'Grid' ? <GridViewIcon /> : <ViewListIcon />}
                <span className='ms-1 text-sm'>{view}</span>
                <ArrowDownRoundedIcon sx={{ fontSize: '22px !important' }} />
              </IconButton>

              <FilterAltIcon sx={{ color: '#776E65' }} />
            </Box>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            gap='16px'
            marginTop='16px'
            width='100%'
          >
            {products.map((product, index) => (
              <ProductListItem
                key={index}
                title={product.itemName}
                description={product.description}
                location={product.location}
                visibility={product.type}
                date={product.date}
                imageUrl={product.image}
                onEdit={() => console.log('Edit clicked')}
                onDelete={() => console.log('Delete clicked')}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
