import React, { Fragment } from 'react';
import { SearchBar } from '../SearchBar';
import { backgroundImage, getStaticTexts } from '../../../assets';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { Button } from '../../Button';
import { useNavigate } from 'react-router-dom';
import SearchInputComponent from '../SearchInputComponent';
import { useSelector } from 'react-redux';

export const ContentBanner = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any)=> state.language);
  const staticTexts = getStaticTexts(language);

  return (
    <Fragment>
      <Box
        className='w-full h-64 opacity-100 bg-cover bg-center flex-col flex justify-center items-center'
        style={{ background: 'linear-gradient(273deg, #FFE0B5 0%, #FAB98A 100%) 0% 0% / cover no-repeat' }}
      >
        <Box textAlign='center'>
          <Typography
            component='p'
            fontSize='26px'
            color='#4A403A'
            letterSpacing='0.01px'
          >
            {staticTexts.weHaveCreatedHappyLosers}
          </Typography>
          <Typography
            className='opacity-60'
            variant='subtitle2'
            fontSize='16px'
            color='#4A403A'
            fontWeight='normal'
          >
            {staticTexts.lqeetReunitesProperty}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: '32px',
            width: { xs: '90%', md: 'fit-content' },
            display: 'flex',
            gap: { xs: 2, sm: 3 },
            flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SearchBar handleFocus={()=>{}}  />
          <Button
            buttonStyle={{
              background: '#FFE0B5',
              border: '1px solid #93897F',
              color: '#4A403A',
              maxWidth: 'fit-content',
            }}
            onClick={() => navigate('/report-found-item')}
          >
           {staticTexts.iHaveFoundSomething}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};
