import { Fragment, useReducer, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { PasswordInput, Input, CustomizedSnackbar } from '../../../components';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { getStaticTexts } from '../../../assets';
import { useSelector } from 'react-redux';

const initialState = {
  loginType: '',
};

interface FormFields {
  email: string;
  password: string;
}

type Action = { type: 'LOGIN_TYPE'; payload: string } | { type: 'VERIFY_OTP' } | { type: 'RESET' };

const businessLoginReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'LOGIN_TYPE':
      return { ...state, loginType: action.payload };
    default:
      return state;
  }
};

export const BusinessLogin = () => {
  const [state, dispatch] = useReducer(businessLoginReducer, initialState);
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const onSubmit = (data: any) => {
    console.log('Form Submitted:', data);
     navigate('/business-manage-items', { state: { showOverview: true } });
  };

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <p className='font-medium'>{staticTexts.welcomeToLqeetBusiness}</p>
          <div className='text-secondary-200 text-sm mt-2'>{staticTexts.manageTrackAndListItems}</div>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className='mt-6'>
            {!state.loginType && (
              <Fragment>
                <Box className='flex items-center rounded-md py-2'>
                  <Input
                    name='email'
                    className='!mb-1'
                    label={staticTexts.emailUserName}
                    placeholder={staticTexts.enterYourFullName}
                    control={control}
                    error={errors.email}
                    isRequired
                  />
                </Box>
                <Box className='flex items-center rounded-md py-2'>
                  <PasswordInput
                    name='password'
                    label='Password'
                    className='!mb-1'
                    control={control}
                    error={errors.password}
                    validationRules={{
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters',
                      },
                    }}
                  />
                </Box>
                <Typography
                  className='text-accent text-right text-xs cursor-pointer'
                  onClick={() => navigate('/auth/forgot-password', {state: 'business'})}
                >
                  {staticTexts.forgotPassword}
                </Typography>
              </Fragment>
            )}
          </Box>

          <Box className='flex gap-4 mt-8 justify-center items-center'>
            <button
              type='submit'
              className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
            >
              {staticTexts.logInToDashboard}
            </button>
          </Box>
        </form>
        <p className='text-gray-500 text-xs text-center mt-8'>
          {staticTexts.dontHaveAnAccount} <span className='underline'>{staticTexts.getMoreInfo}</span>
        </p>
      </Box>
      {/* <CustomizedSnackbar/> */}
    </Fragment>
  );
};
