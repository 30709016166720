import React, { Fragment, useReducer, useCallback, Suspense, useMemo, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Button,
  Popover,
  Grid2,
} from '@mui/material';
import { arabicLogo, getStaticTexts, iPhoneImg, logo, ProfileIcon } from '../../../assets';
import { KeyboardArrowDown } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from '../../Dialog';
import { BadgeAvatars } from '../../BadgedAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { SET_LANGUAGE } from '../../../redux';

// Initial state for useReducer
const initialState = {
  anchorEl: null,
  businessAnchorEl: null,
  profileAnchorEl: null,
  isOpen: false,
  selectedLanguage: localStorage.getItem('lang') === 'ar' ? 'Arabic' : 'English',
};

const notifications = [
  {
    id: 1,
    type: 'message',
    title: 'New Message from Emma',
    content: 'Hi! I believe I found your backpack. Let me know!',
    time: 'Now',
    unread: true,
  },
  {
    id: 2,
    type: 'item-update',
    title: 'Claim Request for Black Wallet',
    content: 'Someone has requested to claim your lost item: Black Wallet.',
    time: '5 min ago',
    unread: true,
    actions: ['Dismiss', 'View'],
  },
  {
    id: 3,
    type: 'message',
    title: 'New Message from Athena',
    content: 'Hi! I found a bag matching your description. Let’s connect.',
    time: '5 min ago',
    unread: true,
  },
  {
    id: 4,
    type: 'account',
    title: 'Password Changed Successfully',
    content: "Your account password was changed. Contact support if this wasn't you.",
    time: '10 min ago',
    unread: false,
  },
  {
    id: 5,
    type: 'system',
    title: 'New Feature Alert!',
    content: 'Explore the new updates to help you find and claim items easily.',
    time: '3 days ago',
    unread: false,
  },
];

// Reducer function for state management
function headerReducer(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'SET_ANCHOR_EL':
      return { ...state, anchorEl: action.payload };
    case 'SET_PROFILE_ANCHOR_EL':
      return { ...state, profileAnchorEl: action.payload };
    case 'SET_BUSINESS_ANCHOR_EL':
      return { ...state, businessAnchorEl: action.payload };
    case 'CLOSE_MENUS':
      return { ...state, anchorEl: null, profileAnchorEl: null, businessAnchorEl: null };
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_DRAWER':
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
}

/**
 * Author: Mayank
 * Date: 19 Nov 2024
 * Description: Header component with logo, navigation links, notification, and profile section.
 * @returns {JSX.Element} Header component
 */

export const Header = React.memo(() => {
  const [state, dispatch] = useReducer(headerReducer, initialState);
  const language = useSelector((state: any) => state.language);
  const appDispatch = useDispatch();
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isBusinessUser, setIsBusinessUser] = useState(false);
  const { pathname } = useLocation();
  const handleDialogClose = () => setOpenDialog(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const individualMenuItems = [
    { label: staticTexts.myItems, path: '/my-items' },
    { label: staticTexts.addItems, path: '/add-first-item' },
    { label: staticTexts.myAccount, path: '/profile' },
    { label: staticTexts.signOut, path: null },
  ];

  // Menu options for business users
  const businessMenuItems = [
    { label: 'Manage Items', path: '/business-manage-items' },
    { label: 'Add Items', path: '/business-add-first-item' },
    { label: 'Claim Requests', path: '/claim-requests' },
    { label: 'My Account', path: '/business/profile' },
  ];

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const languages = [
    { label: 'English', value: 'en' },
    { label: 'Arabic', value: 'ar' },
  ];
  const businessOptions = [
    {
      label: staticTexts.signInForBusiness,
      link: '/auth/business-login',
    },
  ];

  const handleLanguageClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => dispatch({ type: 'SET_ANCHOR_EL', payload: event.currentTarget }),
    []
  );

  const handleBusinessClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) =>
      dispatch({ type: 'SET_BUSINESS_ANCHOR_EL', payload: event.currentTarget }),
    []
  );

  const handleProfileClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => dispatch({ type: 'SET_PROFILE_ANCHOR_EL', payload: event.currentTarget }),
    []
  );

  const handleClose = useCallback(() => {
    dispatch({
      type: 'CLOSE_MENUS',
      payload: undefined,
    });
  }, []);

  const toggleDrawer = useCallback(
    () =>
      dispatch({
        type: 'TOGGLE_DRAWER',
        payload: undefined,
      }),
    []
  );

  const notificationsLabel = useCallback((count: number) => {
    if (count === 0) return 'no notifications';
    if (count > 99) return 'more than 99 notifications';
    return `${count} notifications`;
  }, []);

  const getMenuOptions = useCallback(() => {
    const isLoggedIn = true;
    const options = isLoggedIn
      ? [
          { label: staticTexts.myItems, redirectTo: '/my-items' },
          { label: staticTexts.addItems, redirectTo: '/add-first-item' },
          { label: staticTexts.myAccount, redirectTo: '/profile' },
          { label: staticTexts.signOut, redirectTo: '' },
        ]
      : [
          { label: staticTexts.login, redirectTo: '/login' },
          { label: staticTexts.signUp, redirectTo: '/signup' },
        ];

    return (
      <Fragment>
        {isLoggedIn ? (
          <Fragment>
            {/* Logged-in User */}
            <ListItem
              key={'Mayank'}
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
              >
                <ListItemIcon>
                  {/* User avatar */}
                  <Avatar
                    src={ProfileIcon}
                    imgProps={{ loading: 'lazy' }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '22px',
                      letterSpacing: '0.01px',
                    },
                  }}
                  primary={'Mayank'}
                />
              </ListItemButton>
            </ListItem>
            {/* Options for the logged-in user */}
            {options.map((option, index) => (
              <ListItem
                key={option.label}
                disablePadding
              >
                <ListItemButton
                  sx={{
                    paddingX: '24px',
                  }}
                >
                  <ListItemText
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '22px',
                        letterSpacing: '0.01px',
                        color: option.label === 'Sign out' ? '#AA263F' : '#FFF7E6',
                      },
                    }}
                    primary={option.label}
                    onClick={() => navigate(option.redirectTo)}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Fragment>
        ) : (
          // Guest User
          options.map((option, index) => (
            <ListItem
              key={option.label}
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '22px',
                      letterSpacing: '0.01px',
                    },
                  }}
                  primary={option.label}
                  onClick={() => navigate(option.redirectTo)}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </Fragment>
    );
  }, [
    staticTexts.addItems,
    staticTexts.login,
    staticTexts.myAccount,
    staticTexts.myItems,
    staticTexts.signOut,
    staticTexts.signUp,
  ]);

  const handleLanguageChange = (lang: string) => {
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        selectedLanguage: lang === 'en' ? 'English' : 'Arabic',
      },
    });
    appDispatch({
      type: SET_LANGUAGE,
      payload: lang,
    });
    localStorage.setItem('lang', lang);
    handleClose();
    return;
  };

  return (
    <nav className='min-w-96'>
      <div className='w-full h-14 bg-secondary-400 opacity-100 flex justify-center items-center'>
        <div className='w-full md:w-4/5 flex justify-between items-center'>
          {/* Logo Section */}
          <Suspense fallback={<div>Loading...</div>}>
            <div
              className='w-24 h-full opacity-100 ps-6 md:pl-0 font-extrabold cursor-pointer flex justify-start items-center'
              onClick={() => navigate('/home')}
            >
              <img
                src={language === 'ar' ? arabicLogo : logo}
                alt='lqeet-logo'
                loading='lazy'
              />
            </div>
          </Suspense>

          {/* Right Section */}
          {!pathname.includes('auth') && (
            <div className='flex items-center text-center gap-4'>
              {/* Navigation Links */}
              <div className={`lg:flex hidden space-x-6 rtl:space-x-reverse text-white text-sm`}>
                <button
                  onClick={handleBusinessClick}
                  className='flex items-center space-x-1 rtl:space-x-reverse cursor-pointer'
                >
                  <span>{staticTexts.lqeetForBusiness}</span>
                  <KeyboardArrowDown sx={{ fontSize: '22px', color: '#93897F' }} />
                </button>
                <Menu
                  anchorEl={state.businessAnchorEl}
                  open={Boolean(state.businessAnchorEl)}
                  onClose={handleClose}
                >
                  {businessOptions.map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => {
                        setIsBusinessUser(true);
                        handleClose();
                        navigate(option.link);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
                <button
                  onClick={handleLanguageClick}
                  className='flex items-center space-x-1 rtl:space-x-reverse cursor-pointer'
                >
                  <span>{state.selectedLanguage}</span>
                  <KeyboardArrowDown sx={{ fontSize: '22px', color: '#93897F' }} />
                </button>
                <Menu
                  anchorEl={state.anchorEl}
                  open={Boolean(state.anchorEl)}
                  onClose={handleClose}
                >
                  {languages.map((language) => (
                    <MenuItem
                      key={language.label}
                      onClick={() => {
                        handleLanguageChange(language.value);
                      }}
                    >
                      {language.label}
                    </MenuItem>
                  ))}
                </Menu>
                <span
                  className='cursor-pointer'
                  onClick={() => navigate('/help')}
                >
                  {staticTexts.help}
                </span>
                <span
                  className='cursor-pointer'
                  onClick={() => navigate('/home')}
                >
                  {staticTexts.home}
                  {/* {pathname.includes('home') && <div className='bg-[#FF6F61] h-1 w-10 rounded-full'></div>} */}
                </span>
                <span className='bg-[#FFFFFF4D] w-[1px] rounded-md'></span>
              </div>

              {/* Notification Section */}
              <IconButton
                aria-label={notificationsLabel(100)}
                onClick={() => navigate('/chat')}
              >
                <Badge
                  variant='dot'
                  overlap='circular'
                  badgeContent=' '
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#FF6F61',
                      color: '#FFF7E6',
                    },
                  }}
                >
                  <ChatBubbleIcon className='text-white' />
                </Badge>
              </IconButton>
              <IconButton
                className='cursor-pointer'
                aria-label={notificationsLabel(100)}
                onClick={handleOpen}
              >
                <Badge
                  badgeContent={100}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#FF6F61',
                      color: '#FFF7E6',
                      width: '16px',
                      height: '14px',
                      fontSize: '9px',
                      top: '4px',
                      right: '2px',
                    },
                  }}
                >
                  <NotificationsIcon className='text-white' />
                </Badge>
              </IconButton>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleNotificationClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className='w-80 max-h-[675px] min-w-[412px] overflow-y-auto p-4 bg-[#FFF7E6] shadow-md rounded-lg'>
                  <div className='flex justify-between items-center mb-4'>
                    <Typography
                      variant='h6'
                      className='font-bold'
                    >
                      Notifications
                    </Typography>
                    <Button
                      size='small'
                      className='text-sm'
                      sx={{
                        color: '#FF6F61',
                      }}
                      onClick={handleClose}
                    >
                      Clear All
                    </Button>
                  </div>
                  {notifications.map((notif) => (
                    <div className='flex gap-4 border-b last:border-none'>
                      <BadgeAvatars />
                      <div
                        key={notif.id}
                        className='flex flex-col py-3 w-full'
                      >
                        <div className='flex justify-between items-start'>
                          <Typography
                            fontSize='14px'
                            color='#4A403A'
                            className='font-medium'
                          >
                            {notif.title}
                          </Typography>
                          <Typography
                            fontSize='12px'
                            color='#655B53FA'
                          >
                            {notif.time}
                          </Typography>
                        </div>
                        <Typography
                          fontSize='12px'
                          color='#655B53FA'
                        >
                          {notif.content}
                        </Typography>
                        {notif.actions && (
                          <div className='mt-2 flex space-x-2'>
                            {notif.actions.map((action) => (
                              <Button
                                key={action}
                                size='small'
                                variant={action === 'View' ? 'contained' : 'outlined'}
                                sx={{
                                  color: action === 'View' ? '#FFF7E6' : '#4A403A',
                                  backgroundColor: action === 'View' ? '#FF6F61' : '',
                                  borderColor: action === 'View' ? '' : '#C9C2B4',
                                }}
                                onClick={() => {
                                  if (action === 'View') {
                                    setOpenDialog(true);
                                    handleNotificationClose();
                                  }
                                }}
                              >
                                {action}
                              </Button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {!notifications.length && (
                    <Typography className='text-center text-gray-500'>No notifications available.</Typography>
                  )}
                </div>
              </Popover>

              {/* Profile Section */}
              <Box className='hidden lg:block'>
                <IconButton
                  onClick={handleProfileClick}
                  className='rtl:flex-row-reverse'
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <Avatar
                      className='bg-green-500'
                      src={ProfileIcon}
                      imgProps={{ loading: 'lazy' }}
                    ></Avatar>
                  </Suspense>
                  <span className='text-white text-sm p-2 rtl:text-right'>Mayank..</span>
                  <KeyboardArrowDown className='text-[#FFF7E6]' />
                </IconButton>
                <Menu
                  anchorEl={state.profileAnchorEl}
                  open={Boolean(state.profileAnchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  className='rtl:ltr' // add this class to reverse the menu direction in RTL layout
                >
                  {isBusinessUser
                    ? businessMenuItems.map((option) => (
                        <MenuItem
                          key={option.label}
                          onClick={() => {
                            if (option.path) navigate(option.path);
                            handleClose();
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))
                    : individualMenuItems.map((option) => (
                        <MenuItem
                          key={option.label}
                          onClick={() => {
                            if (option.path) navigate(option.path);
                            handleClose();
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                </Menu>
              </Box>

              {/* Mobile View */}
              <div className='lg:hidden me-4'>
                <IconButton
                  className='!text-[#FFF7E6]'
                  onClick={toggleDrawer}
                >
                  {state.isOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Drawer */}
      <Drawer
        anchor='bottom'
        open={state.isOpen}
        sx={{
          height: 'fit-content',
          '& .MuiDrawer-paper': {
            height: 'calc(100vh - 56px)',
            overflow: 'hidden',
            backgroundColor: '#4A403A',
            opacity: 1,
            boxShadow: 'none',
            color: '#FFF7E6',
          },
        }}
        onClose={toggleDrawer}
        hideBackdrop={true}
      >
        <Box
          sx={{ width: 'auto' }}
          role='presentation'
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <List>
            {getMenuOptions()}
            <ListItem
              key='home'
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
                onClick={() => navigate('/home')}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '18px',
                    },
                  }}
                  primary={'Home'}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              key='help'
              disablePadding
            >
              <ListItemButton
                sx={{
                  paddingX: '24px',
                }}
                onClick={() => navigate('/help')}
              >
                <ListItemText
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: '18px',
                    },
                  }}
                  primary={'Help'}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        title='Claim Item details'
        maxWidth='lg'
        titlePadding='16px 24px'
        contentPadding='0px 24px'
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Typography
              variant='h6'
              fontSize='16px'
              color='#4A403A'
              gutterBottom
            >
              Item details
            </Typography>
            <Grid2
              container
              spacing={2}
            >
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Item Name
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Black wallet with driver’s license and credit cards.
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Description
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Black wallet with driver’s license and credit cards.
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Location
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Downtown Café, 5th Avenue
                  </Typography>
                </Box>
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Full Name
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    John Doe
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Email Address
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    johndoe@example.com
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Date Lost
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    16 Oct, 2024
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    What's the brand
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    Gucci
                  </Typography>
                </Box>

                <Box marginBottom='24px'>
                  <Typography
                    component='p'
                    fontSize='12px'
                    color='#776E65'
                  >
                    Last 4 digits of the account number{' '}
                  </Typography>
                  <Typography
                    component='p'
                    fontSize='14px'
                    color='#4A403A'
                  >
                    XXXXXX1234{' '}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>

            {/* Uploaded Images Section */}
            <Box mt={4}>
              <Typography
                variant='h6'
                fontSize='16px'
                color='#4A403A'
                gutterBottom
              >
                Uploaded images
              </Typography>
              <Box
                display='flex'
                gap='8px'
                paddingBottom='24px'
              >
                <img
                  src={iPhoneImg}
                  alt='Item 1'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
                <img
                  src={iPhoneImg}
                  alt='Item 2'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
                <img
                  src={iPhoneImg}
                  alt='Item 3'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
                <img
                  src={iPhoneImg}
                  alt='Item 4'
                  style={{ width: '60px', height: '60px', borderRadius: '8px' }}
                />
              </Box>
            </Box>
          </Box>
        }
      />
    </nav>
  );
});
