import React, { useState, useEffect, Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';

const OTPInuptComponent: React.FC<{
  otp: string[];
  onChange: (index: number, value: string) => void;
  error?: string;
}> = ({ otp, onChange, error }) => {
  const [timer, setTimer] = useState<number>(30);
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const isRTL = language === 'ar';

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleChange = (value: string, index: number) => {
    if (/^[0-9]$/.test(value) || value === '') {
      onChange(index, value);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`)?.focus();
      }
    }
  };
 
  return (
    <Fragment>
      <div className='flex justify-center space-x-2 sm:space-x-3 relative'>
        <label
          htmlFor={`otp-0`}
          className={`w-fit absolute -top-2 ${
            isRTL ? 'right-10 md:right-2' : 'left-16 md:left-16 lg:left-1'
          }  bg-[#ECE4D5] px-1 text-gray-600 ${
            error && '!text-error'
          } text-xs transition-all`}
        >
          OTP
        </label>
        {otp.map((value, index) => (
          <Fragment key={index}>
            <input
              id={`otp-${index}`}
              placeholder='0'
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              className={`
              text-center
              ${index === 0 && '!ms-0'}
              text-lg sm:text-xl 
              w-10 sm:w-12 md:w-14 lg:w-24
              h-10 sm:h-12 
              bg-[#ECE4D5] 
              border border-gray-800
              ${error && '!border-error'}
              focus:outline-none 
              rounded 
              custom-placeholder
            `}
            />
          </Fragment>
        ))}
      </div>
      {error && (
        <p className='text-error flex justify-center md:justify-start md:text-left text-center text-xs w-full mt-1 md:ms-4'>
          {error}
        </p>
      )}

      <p className='text-secondary-light flex justify-start text-left text-xs w-full mt-1 ms-4'>
        {staticTexts.didNotReceiveACode} 30 {staticTexts.seconds}
      </p>
    </Fragment>
  );
};

export const OTPInupt = memo(OTPInuptComponent);
