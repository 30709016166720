import React, { useState } from 'react';
import { Button, DateInput, Dropdown, Input, ImageUpload, LocationInput, Modal } from '../../components';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';

export const ReportFoundItem = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<{
    itemName: string;
    category: string;
    description: string;
    date: string;
    location: string;
    privacy: string;
  }>();
  const navigate = useNavigate();
  const [locationValue, setLocationValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const handleClose = () => setIsOpen(false);
  const handleConfirm = () => {
    navigate('/my-items');
    setIsOpen(false);
  };
  
    const handleLocationValueChange = (location: { lat: number; lng: number; description: string }) => {
      console.log('Selected Location:', location);
      setValue('location', location?.description);
    };
  

  const handleCurrentLocationClick = (
    location: { latitude: number; longitude: number } | null,
    error: string | null
  ) => {
    if (error) {
      console.error(error);
    } else {
      console.log('Current Location:', location);
    }
  };
  const onSubmit = (data: any) => {
    setIsOpen(true);
    console.log('Form Submitted:', data);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      marginTop='64px'
    >
      <Typography
        component='p'
        color='#4A403A'
        marginBottom='40px'
        fontSize='26px'
        textAlign='center'
        letterSpacing='0.01px'
      >
        {staticTexts.whatHaveYouFound}
      </Typography>

      <Box
        bgcolor='#FFF7E6'
        paddingX='32px'
        paddingY='40px'
        minWidth='50%'
        borderRadius='8px'
        maxWidth='640px'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name='itemName'
            className='!mb-6'
            label={staticTexts.itemNameWhatIFound}
            control={control}
            error={errors.itemName}
            isRequired
          />

          <Dropdown
            name='category'
            dropdownStyle={{ marginBottom: '24px' }}
            control={control}
            options={['Electronic', 'Automobile']}
            labelText={staticTexts.selectCategory}
            isRequired={true}
            error={errors.category}
          />

          <ImageUpload />

          <Input
            name='description'
            label={staticTexts.foundDescription}
            sx={{ marginBottom: '24px' }}
            placeholder='e.g.,"Describe item in detail"'
            fullWidth
            multiline
            rows={4}
            control={control}
            error={errors.description}
            isRequired
          />

          <LocationInput
            name='location'
            control={control}
            label={staticTexts.foundLocation}
            onDialogToggle={()=>setIsDialogOpen(true)}
            onCurrentLocationClick={handleCurrentLocationClick}
            handleLocationSelect={handleLocationValueChange}
            rules={{
              required: 'Location is required',
            }}
          />

          <DateInput
            name='date'
            className='w-full'
            label={staticTexts.whenYouHaveFoundIt}
            control={control}
            error={errors.date}
            helperText={errors.date?.message || ' '}
            validationRules={{
              required: 'Date is required',
            }}
          />

          <Box marginTop='8px'>
            <FormControl>
              <FormLabel
                id='privacy-setting'
                sx={{
                  color: '#4A403A',
                  fontSize: '14px',
                  '&.Mui-focused': {
                    color: '#4A403A',
                  },
                }}
              >
                {staticTexts.privacySetting}
              </FormLabel>
              <Controller
                name='privacy'
                control={control}
                defaultValue='private'
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby='privacy-setting'
                    className='flex gap-4 !flex-row'
                  >
                    <FormControlLabel
                      value='private'
                      control={<Radio />}
                      label={staticTexts.private}
                    />
                    <FormControlLabel
                      value='public'
                      control={<Radio />}
                      label={staticTexts.public}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Box>

          <Box
            display='flex'
            justifyContent='space-between'
            marginTop='24px'
          >
            <Button
              variant='text'
              buttonStyle={{ color: '#FF6F61' }}
              onClick={() => navigate(-1)}
            >
              {staticTexts.back}
            </Button>
            <Button
              buttonStyle={{ background: '#FF6F61' }}
              variant='contained'
              type='submit'
            >
              {staticTexts.submit}
            </Button>
          </Box>
        </form>
      </Box>

      <Modal
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        confirmText='View My Listings'
        cancelText='Close'
        customStyles={{
          width: '360px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 40, color: '#FF6F59', mb: 2 }} />
        <Typography
          component='p'
          letterSpacing='0.01px'
          fontWeight={400}
          fontSize='22px'
          sx={{ mb: 1 }}
        >
          Item Posted Successfully!
        </Typography>
        <Typography
          variant='body1'
          fontSize='14px'
          sx={{ color: '#655B53FA' }}
        >
          Your found item has been listed. We'll notify you if someone reports losing it!
        </Typography>
      </Modal>
    </Box>
  );
};
