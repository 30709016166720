import React, { Fragment } from 'react';
import { OTPInupt } from '../../../../components/OTPInput';
import { Box, Typography } from '@mui/material';
import { Button } from '../../../../components';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../../assets';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

export const LoginWithOTP = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: ['', '', '', ''],
    },
  });

  const onSubmit = (data: { otp: string[] }) => {
    // Combine OTP values
    const otp = data.otp.join('');
    if (otp.length !== 4) {
      setError('otp', { type: 'manual', message: 'OTP must be 4 digits.' });
      return;
    }
    // Handle OTP validation logic here
    console.log('OTP Submitted:', otp);
    navigate('/home');
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className='flex flex-col items-center rounded-md py-2'>
          <Box
            className='sm:justify-center md:self-start'
            marginTop='45px'
          >
            <Controller
              name='otp'
              control={control}
              rules={{
                validate: (value) => value.every((v) => /^[0-9]$/.test(v)) || `OTP ${staticTexts.isRequired}`,
              }}
              render={({ field }) => (
                <OTPInupt
                  otp={field.value}
                  onChange={(index: number, value: string) => {
                    const updatedOtp = [...field.value];
                    updatedOtp[index] = value;
                    field.onChange(updatedOtp);
                  }}
                  error={errors?.otp?.message}
                />
              )}
            />
            <Typography
              width='100%'
              fontSize='12px'
              color='#4A403A'
              marginTop='24px'
              textAlign='left'
            >
              {staticTexts.tryLogginInWithPassword}{' '}
              <span
                className='underline text-accent cursor-pointer'
                onClick={() => navigate('/auth/login/password')}
              >
                {staticTexts.usePassword}
              </span>
            </Typography>
          </Box>
          <Button
            type='submit'
            className='px-6 py-2 rounded-lg w-fit text-center text-sm'
            buttonStyle={{ marginTop: '40px' }}
          >
            {staticTexts.loginWithOTP}
          </Button>
        </Box>
      </form>
    </Fragment>
  );
};
