import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import AppRouter from './routes';

/**
 * Author: Mayank
 * Date: 8 December 2024
 *
 * Summary:
 * This file defines the main `App` component for the React application.
 * It integrates routing, handles language-based directionality (RTL/LTR), and applies
 * global styling configurations.
 *
 * Description:
 * - Imports necessary dependencies, including React, routing configuration, Redux for state management,
 *   and Emotion for styling.
 * - Determines the text direction (`ltr` or `rtl`) based on the selected language from the Redux store.
 * - Utilizes Emotion's `CacheProvider` and `stylis-plugin-rtl` to create an RTL cache for styles
 *   when required.
 * - Dynamically updates the `dir` attribute of the HTML `<html>` element based on the language's direction.
 * - Conditionally wraps the application's routing logic in an Emotion `CacheProvider` if RTL styling is needed.
 */

function App() {
  const language = useSelector((state: any) => state.language) || 'ar';
  const rtlLanguages = [
    'ar', // Arabic
    'fa', // Persian (Farsi)
    'he', // Hebrew
    'ur', // Urdu
  ];

  const direction = rtlLanguages.includes(language) ? 'rtl' : 'ltr';

  // Create RTL cache for Emotion styles
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
  });

  // Update the document's direction for global HTML direction
  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
  }, [direction]);

  return (
    <>
      {direction === 'rtl' ? (
        <CacheProvider value={cacheRtl}>
          <RouterProvider router={routes} />
        </CacheProvider>
      ) : (
        <RouterProvider router={routes} />
      )}
    </>
  );
}

export default App;
