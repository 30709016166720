import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ProductListItem, SearchBar } from '../../components';
import { SET_VIEW } from '../../redux/actions/my-items';
import ViewListIcon from '@mui/icons-material/ViewListRounded';
import GridViewIcon from '@mui/icons-material/GridViewRounded';
import { useDispatch } from 'react-redux';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { iPhoneImg } from '../../assets';
import ProductCard from '../../components/Layouts/ProductCard';

interface LostItemsHeaderProps {
  selectedTab: string;
  itemCount: number;
  onReportClick: () => void;
  onViewChange: (view: string) => void;
}

const products = [
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Iphone 6',
    description: 'New Apple iPhone 16 Pro, 256GB.',
    location: 'Mohali',
    date: '10 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Samsung Galaxy',
    description: 'Samsung Galaxy S21, 128GB.',
    location: 'Chandigarh',
    date: '12 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Black Leather Wallet',
    description: "Wallet with driver's license and cards.",
    location: 'Downtown Café, 5th Avenue',
    date: '13 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Ray-Ban Sunglasses',
    description: 'Classic Ray-Ban sunglasses.',
    location: 'Park Mall',
    date: '15 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'AirPods Pro',
    description: 'Apple AirPods Pro, with case.',
    location: 'City Center',
    date: '17 Oct 2024',
    type: 'Private',
  },
];
const LostItemsHeader: React.FC<LostItemsHeaderProps> = ({ itemCount, selectedTab, onReportClick, onViewChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [view, setView] = React.useState('Grid');

  const dispatch = useDispatch();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewChange = (newView: string) => {
    setView(newView);
    dispatch({ type: SET_VIEW, payload: newView });
    onViewChange(newView);
    handleMenuClose();
  };

  return (
    <Box className='w-full flex items-center justify-between pt-8 pb-2 min-w-16'>
      {/* Title and Count */}
      <Box className='flex items-center space-x-4'>
        <Typography
          variant='h6'
          className='text-2xl font-semibold'
        >
          {selectedTab}
        </Typography>
      </Box>

      {/* View Options and Report Button */}
      <Box className='flex items-center space-x-2'>
        {/* View Button with Dropdown */}
        <span className='text-secondary-200 text-sm'>{itemCount} lost items</span>
        <IconButton
          onClick={handleMenuClick}
          className='border border-blue-500'
          size='small'
          disableRipple
          sx={{
            // width: 14,
            height: 18,
            '& .MuiSvgIcon-root': {
              fontSize: 14,
            },
          }}
        >
          {view === 'Grid' ? <GridViewIcon /> : <ViewListIcon />}
          <span className='ms-1 text-sm'>{view}</span>
          <ArrowDownRoundedIcon fontSize='small' />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: 1,
              backgroundColor: '#FFF7E6',
              minWidth: 150,
            },
          }}
        >
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: '#FFE0B5',
              },
            }}
            onClick={() => handleViewChange('Grid')}
          >
            <GridViewIcon fontSize='small' />
            <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>Grid</span>
          </MenuItem>
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: '#FFE0B5',
              },
            }}
            onClick={() => handleViewChange('List')}
          >
            <ViewListIcon fontSize='small' />
            <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>List</span>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const OrganizationPage = () => {
  const [viewType, setViewType] = useState('Grid');
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        className='lg:flex'
        gap='12px'
        width='80%'
        height='700px'
        marginTop='36px'
      >
        <Box
          className='hidden lg:block min-w-72'
          width='30%'
          padding='4px'
        >
          <Box
            className='flex justify-center items-center flex-col min-h-80 rounded-lg p-8'
            bgcolor='#FFF7E6'
          >
            <img
              className='rounded-full'
              src={iPhoneImg}
              alt='Item 1'
              style={{ width: '60px', height: '60px' }}
            />

            <Typography
              color='#4A403A'
              fontSize='18px'
              textAlign='center'
              marginTop='24px'
            >
              Vertex Solutions
            </Typography>

            <Typography
              fontSize='14px'
              color='#655B53FA'
              textAlign='center'
            >
              1234 Elm Street, Suite 567, San Francisco, CA 94108, Unit…
            </Typography>

            <Button
              variant='contained'
              sx={{
                background: '#FF6F61',
                color: '#FFF7E6',
                marginTop: '40px',
                fontWeight: '400',
              }}
            >
              Chat with organizations
            </Button>
          </Box>

          <Box
            className='rounded-lg'
            marginTop='12px'
            padding='32px'
            bgcolor='#FFF7E6'
          >
            <Typography
              color='#4A403A'
              textAlign='left'
              marginBottom='12px'
              fontSize='16px'
            >
              Were you not able to find what you lost?
            </Typography>

            <Typography
              color='#655B53FA'
              fontSize='14px'
              textAlign='left'
              marginBottom='40px'
            >
              Were you not able to find what you lost?
            </Typography>

            <Button
              variant='outlined'
              sx={{ border: '1px solid #C9C2B4', fontWeight: '400', color: '#4A403A' }}
            >
              Search all found items
            </Button>
          </Box>
        </Box>

        <Box
          className='rounded-lg p-8'
          bgcolor='#FFF7E6'
          overflow='scroll'
          style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}
        >
          <SearchBar handleFocus={()=>{}} />

          <LostItemsHeader
            selectedTab='Lost Items'
            onViewChange={() => {}}
            itemCount={100}
            onReportClick={() => {}}
          />
          {viewType === 'Grid' ? (
           <div
           style={{
             display: 'grid',
             gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
             gap: '1rem',
           }}
         >
           {products.map((product, index) => (
             <ProductCard
               key={index}
               image={product.image}
               itemCategory={product.itemCategory}
               itemName={product.itemName}
               description={product.description}
               location={product.location}
               date={product.date}
               type={product.type}
               showActions={false}
               showPrivacy={false}
               showClaimButton={true}
             />
           ))}
         </div>
          ) : (
            <div className='grid grid-cols-1 gap-2'>
              {products.map((product, index) => (
                <ProductListItem
                  key={index}
                  title={product.itemName}
                  description={product.description}
                  location={product.location}
                  visibility={product.type}
                  date={product.date}
                  imageUrl={product.image}
                  onDelete={() => console.log('Delete clicked')}
                />
              ))}
            </div>
          )}
        </Box>

        <Box
          className='block lg:hidden max-w-full'
          padding='4px'
        >
          <Box
            className='flex justify-center items-center flex-col min-h-80 rounded-lg p-8'
            bgcolor='#FFF7E6'
          >
            <img
              className='rounded-full'
              src={iPhoneImg}
              alt='Item 1'
              style={{ width: '60px', height: '60px' }}
            />

            <Typography
              color='#4A403A'
              fontSize='18px'
              textAlign='center'
              marginTop='24px'
            >
              Vertex Solutions
            </Typography>

            <Typography
              fontSize='14px'
              color='#655B53FA'
              textAlign='center'
            >
              1234 Elm Street, Suite 567, San Francisco, CA 94108, Unit…
            </Typography>

            <Button
              variant='contained'
              sx={{
                background: '#FF6F61',
                color: '#FFF7E6',
                marginTop: '40px',
                fontWeight: '400',
              }}
            >
              Chat with organizations
            </Button>
          </Box>

          <Box
            className='rounded-lg'
            marginTop='12px'
            padding='32px'
            bgcolor='#FFF7E6'
          >
            <Typography
              color='#4A403A'
              textAlign='left'
              marginBottom='12px'
              fontSize='16px'
            >
              Were you not able to find what you lost?
            </Typography>

            <Typography
              color='#655B53FA'
              fontSize='14px'
              textAlign='left'
              marginBottom='40px'
            >
              Were you not able to find what you lost?
            </Typography>

            <Button
              variant='outlined'
              sx={{ border: '1px solid #C9C2B4', fontWeight: '400', color: '#4A403A' }}
            >
              Search all found items
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationPage;
