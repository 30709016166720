import { Box } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../assets';
import { OTPInupt } from '../../../components';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';
import { Controller, useForm } from 'react-hook-form';

const ForgotPassword = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), []);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      otp: ['', '', '', ''],
    },
  });

  const onSubmit = (data: { otp: string[] }) => {
    const otp = data.otp.join('');
    if (otp.length !== 4) {
      setError('otp', { type: 'manual', message: 'OTP must be 4 digits.' });
      return;
    }

    console.log('OTP Submitted:', otp);
    navigate('/auth/reset-password');
  };

  const getHeaderText = () => {
    if (state === 'business') {
      return staticTexts.enterEmailAddressToReceiveResetCode;
    }
    return staticTexts.enterYourRegisteredMobileNumber + ' ' + staticTexts.aResetCode;
  };

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <p className='font-medium'>{staticTexts.forgotPassword}</p>
          <div className='text-secondary-200 text-sm mt-2 max-w-full text-center'>{getHeaderText()}</div>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className='flex flex-col items-center rounded-md py-2'>
            <Box className='mt-6 sm:justify-center md:self-start'>
              <Controller
                name='otp'
                control={control}
                rules={{
                  validate: (value) => value.every((v) => /^[0-9]$/.test(v)) || 'OTP is required.',
                }}
                render={({ field }) => (
                  <OTPInupt
                    otp={field.value}
                    onChange={(index: number, value: string) => {
                      const updatedOtp = [...field.value];
                      updatedOtp[index] = value;
                      field.onChange(updatedOtp);
                    }}
                    error={errors?.otp?.message}
                  />
                )}
              />
              <div className='text-secondary-400 text-xs mt-6 text-left'>{staticTexts.weWillSendAnOTP}</div>
            </Box>

            <Box className='flex gap-4 mt-8 justify-center items-center'>
              <Button
                variant='outlined'
                className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
                onClick={() => navigate(-1)}
                buttonStyle={{
                  color: '#111111D8',
                  border: '1px solid #C9C2B4',
                }}
              >
                {staticTexts.backToLogin}
              </Button>

              <Button
                type='submit'
                className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
              >
                {staticTexts.continue}
              </Button>
            </Box>
          </Box>
        </form>
        <p
          className='text-gray-500 text-xs text-center mt-8 cursor-pointer'
          onClick={() => {}}
        >
          {staticTexts.needHelp} <span className='underline'>{staticTexts.contactSupport}</span>
        </p>
      </Box>
    </Fragment>
  );
};

export default ForgotPassword;
