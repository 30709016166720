import React, { Fragment, useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  SwipeableDrawer,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '../../Button';
import { grey } from '@mui/material/colors';
import { Modal } from '../../Modal';

const FilterDrawer = ({
  onApply,
  isOpen,
  toggleDrawer,
}: {
  onApply: (filters: any) => void;
  isOpen: boolean;
  toggleDrawer: (state: boolean) => void;
}) => {
  const [expandedViewMode, setExpandedViewMode] = useState(false);
  const [expandedDateRange, setExpandedDateRange] = useState(false);
  const [filters, setFilters] = useState({
    sortBy: 'newest',
    viewMode: 'list',
    dateRange: 'today',
    customDate: { from: '', to: '' },
  });

  const handleApply = () => {
    onApply(filters);
    toggleDrawer(false);
  };

  const handleClearAll = () => {
    setFilters({
      sortBy: 'newest',
      viewMode: 'list',
      dateRange: 'today',
      customDate: { from: '', to: '' },
    });
  };

  const handleViewModeToggle = () => {
    setExpandedViewMode(!expandedViewMode);
  };

  const handleDateRangeToggle = () => {
    setExpandedDateRange(!expandedDateRange);
  };

  const Puller = styled('div')(({ theme }) => ({
    width: 36,
    height: 6,
    backgroundColor: '#3C3C434D',
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
    ...theme.applyStyles('dark', {
      backgroundColor: '#3C3C434D',
    }),
  }));

  return (
    <Fragment>
      <SwipeableDrawer
        anchor='bottom'
        sx={{ '& .MuiDrawer-paper': { height: '90%', borderRadius: '12px 12px 0px 0px', bgcolor: '#FFF7E6' } }}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(false)}
      >
        <Box sx={{ width: '100%', padding: 2 }}>
          {/* Header */}
          <Puller />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant='h6'
              color='#4A403A'
              fontSize='20px'
              textAlign='left'
            >
              Sort & Filter
            </Typography>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
            <Button
              buttonStyle={{
                border: '1px solid #C9C2B4',
                color: '#776E65',
                width: '48%',
              }}
              onClick={handleClearAll}
              variant='outlined'
            >
              Clear all
            </Button>
            <Button
              buttonStyle={{
                color: '#FFF7E6',
                width: '48%',
              }}
              onClick={handleApply}
              variant='contained'
              color='primary'
            >
              Apply
            </Button>
          </Box>

          <Divider />

          {/* Sort By Section */}
          <Box sx={{ mt: 2 }}>
            <Typography
              variant='subtitle1'
              component='p'
              fontSize='18px'
              color='#4A403A'
              fontWeight={400}
              className='!mb-3'
            >
              Sort by
            </Typography>
            <Select
              fullWidth
              value={filters.sortBy}
              onChange={(e) => setFilters({ ...filters, sortBy: e.target.value })}
            >
              <MenuItem value='newest'>Newest → Oldest</MenuItem>
              <MenuItem value='oldest'>Oldest → Newest</MenuItem>
            </Select>
          </Box>
          {/* Filter By Section */}
          <Typography
            variant='subtitle1'
            sx={{ my: 2 }}
            component='p'
            fontSize='18px'
            color='#4A403A'
            fontWeight={400}
          >
            Filter by
          </Typography>

          {/* View Mode */}
          <Accordion
            expanded={expandedViewMode}
            onChange={handleViewModeToggle}
          >
            <AccordionSummary
              expandIcon={
                expandedViewMode ? (
                  <IconButton size='small'>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton size='small'>
                    <Typography variant='h6'>+</Typography>
                  </IconButton>
                )
              }
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography>View Mode</Typography>
                {/* <IconButton
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilters({ ...filters, viewMode: 'list' }); // Clear view mode
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={filters.viewMode}
                onChange={(e) => setFilters({ ...filters, viewMode: e.target.value })}
              >
                <FormControlLabel
                  value='grid'
                  control={<Radio />}
                  label='Grid view'
                />
                <FormControlLabel
                  value='list'
                  control={<Radio />}
                  label='List view'
                />
              </RadioGroup>
            </AccordionDetails>
          </Accordion>

          {/* Date Range */}
          <Accordion
            expanded={expandedDateRange}
            onChange={handleDateRangeToggle}
          >
            <AccordionSummary
              expandIcon={
                expandedDateRange ? (
                  <IconButton size='small'>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton size='small'>
                    <Typography variant='h6'>+</Typography>
                  </IconButton>
                )
              }
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography>Date Range</Typography>
                {/* <IconButton
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    setFilters({ ...filters, dateRange: 'today' }); // Clear date range
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton> */}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                value={filters.dateRange}
                onChange={(e) => setFilters({ ...filters, dateRange: e.target.value })}
              >
                <FormControlLabel
                  value='today'
                  control={<Radio />}
                  label='Today'
                />
                <FormControlLabel
                  value='yesterday'
                  control={<Radio />}
                  label='Yesterday'
                />
                <FormControlLabel
                  value='lastWeek'
                  control={<Radio />}
                  label='Last week'
                />
                <FormControlLabel
                  value='last2Weeks'
                  control={<Radio />}
                  label='Last 2 Weeks'
                />
                <FormControlLabel
                  value='lastMonth'
                  control={<Radio />}
                  label='Last Month'
                />
                <FormControlLabel
                  value='last3Months'
                  control={<Radio />}
                  label='Last 3 Months'
                />
                <FormControlLabel
                  value='custom'
                  control={<Radio />}
                  label='Custom Date Range'
                />
              </RadioGroup>

              {/* Custom Date Range */}
              {filters.dateRange === 'custom' && (
                <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                  <TextField
                    type='date'
                    label='From'
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    value={filters.customDate.from}
                    onChange={(e) =>
                      setFilters({ ...filters, customDate: { ...filters.customDate, from: e.target.value } })
                    }
                    fullWidth
                  />
                  <TextField
                    type='date'
                    label='To'
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    value={filters.customDate.to}
                    onChange={(e) =>
                      setFilters({ ...filters, customDate: { ...filters.customDate, to: e.target.value } })
                    }
                    fullWidth
                  />
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
          <Divider />
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};

export default FilterDrawer;
