import React from 'react';
import { Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: '#4A403A',
    color: '#FFF7E6te',
    borderRadius: '8px',
    padding: '8px 10px',
    minWidth: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiSnackbarContent-message': {
    padding: '0px',
  },
  '& .MuiIconButton-root': {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.75rem',
  },
  '& .MuiIconButton-root:hover': {
    textDecoration: 'underline',
  },
}));

// export const CustomizedSnackbar = (props:{open: boolean,messageLine1:string, messageLine2:string, onClose:()=> void}) => {
//   const {open,messageLine1, messageLine2,onClose} = props;

//   return (
//     <CustomSnackbar
//       open={open}
//       onClose={onClose}
//       autoHideDuration={5000}
//       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       message={
//         <div>
//           <Typography variant="body1" fontSize='14px'>{messageLine1}</Typography>
//           <Typography variant="body1">
//            {messageLine2}
//           </Typography>
//         </div>
//       }
//       action={
//         <span className='pe-2 text-sm'> CLOSE</span>

//       }
//     />
//   );
// }


// const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
//   '& .MuiSnackbarContent-root': {
//     backgroundColor: '#4A403A',
//     color: '#FFF7E6',
//     borderRadius: '8px',
//     padding: '8px 10px',
//     minWidth: '350px',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
// }));

export const CustomizedSnackbar = ({
  open,
  message,
  description,
  status,
  onClose,
  autoHideDuration,
}: {
  open: boolean;
  message: string;
  description?:string;
  status: string;
  onClose: () => void;
  autoHideDuration?: number;
}) => {
  return (
    <CustomSnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration || 3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={
        <div>
          <Typography variant='body1'>{message}</Typography>
          <Typography variant='body2'>{description}</Typography>
        </div>
      }
      action={<span className='pe-2 text-sm'> CLOSE</span>}
    />
  );
};
