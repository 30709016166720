import React, { Fragment, useEffect } from 'react';
import { ContentBanner, useSnackbar } from '../../components';
import { Outlet } from 'react-router-dom';

export const HomePage: React.FC = () => {
  const enqueueSnackbar = useSnackbar();

  // useEffect(()=>{
  //  // enqueueSnackbar('This is a success message!');
  // },[])

  return (
    <Fragment>
      <ContentBanner />
      <Outlet />
    </Fragment>
  );
};
