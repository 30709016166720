import React, { useState } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { ControlToolbar, DateInput, Modal, ProductListItem } from '../../../components';
import { getStaticTexts, iPhoneImg } from '../../../assets';
import ProductCard from '../../../components/Layouts/ProductCard';
import FilterDrawer from '../../../components/Layouts/FilterDrawer';


const products = [
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Iphone 6',
    description: 'New Apple iPhone 16 Pro, 256GB.',
    location: 'Mohali',
    date: '10 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Samsung Galaxy',
    description: 'Samsung Galaxy S21, 128GB.',
    location: 'Chandigarh',
    date: '12 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Black Leather Wallet',
    description: "Wallet with driver's license and cards.",
    location: 'Downtown Café, 5th Avenue',
    date: '13 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Ray-Ban Sunglasses',
    description: 'Classic Ray-Ban sunglasses.',
    location: 'Park Mall',
    date: '15 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'AirPods Pro',
    description: 'Apple AirPods Pro, with case.',
    location: 'City Center',
    date: '17 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Iphone 6',
    description: 'New Apple iPhone 16 Pro, 256GB.',
    location: 'Mohali',
    date: '10 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Samsung Galaxy',
    description: 'Samsung Galaxy S21, 128GB.',
    location: 'Chandigarh',
    date: '12 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Black Leather Wallet',
    description: "Wallet with driver's license and cards.",
    location: 'Downtown Café, 5th Avenue',
    date: '13 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Ray-Ban Sunglasses',
    description: 'Classic Ray-Ban sunglasses.',
    location: 'Park Mall',
    date: '15 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'AirPods Pro',
    description: 'Apple AirPods Pro, with case.',
    location: 'City Center',
    date: '17 Oct 2024',
    type: 'Private',
  },
];

const SearchItems = () => {
  const [viewType, setViewType] = useState('Grid');
  const [open, setOpen] = useState(false);
  
  const handleViewTypeChange = (value: string) => {
    setViewType(value);
  };

  const toggleDrawer = (state: boolean) => {
    console.log('state', state);
    setOpen(state);
  };

  const onSubmit = () => {
    console.log('called');
  };

  const onClose = () => {};

  return (
    <Box className='w-full'>
      <Box
        className='mx-auto'
        width={{
          xs: '92%',
          md: '80%',
        }}
        minHeight='700px'
      >
        <ControlToolbar
          selectedTab='Wallet in France'
          viewType='Grid'
          onViewChange={handleViewTypeChange}
          itemCount={100}
          showButton={false}
          handleFilters={() => setOpen(true)}
        />

        <Box className='w-full'>
          {viewType === 'List' ? (
            products.map((product, index) => (
              <div className='mb-2'>
                <ProductListItem
                  key={index}
                  title={product.itemName}
                  description={product.description}
                  location={product.location}
                  visibility={product.type}
                  date={product.date}
                  imageUrl={product.image}
                  showActions={false}
                  showClaimButton={true}
                  onDelete={() => console.log('Delete clicked')}
                />
              </div>
            ))
          ) : (
            <div
              className='grid gap-4 place-items-center'
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
                gap: '1rem',
              }}
            >
              {products.map((product, index) => (
                <ProductCard
                  key={index}
                  image={product.image}
                  itemCategory={product.itemCategory}
                  itemName={product.itemName}
                  description={product.description}
                  location={product.location}
                  date={product.date}
                  type={product.type}
                  showActions={false}
                  showPrivacy={false}
                  showClaimButton={true}
                />
              ))}
            </div>
          )}
        </Box>
        <FilterDrawer
          onApply={() => {}}
          isOpen={open}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    </Box>
  );
};

export default SearchItems;
