import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { DateInput, PhoneNumberInput, PasswordInput, Input, UploadBox, Dropdown } from '../../../components';
import { defaultProfileImg, getStaticTexts } from '../../../assets';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface FormFields {
  fullName: string;
  email: string;
  phone: string;
  dob: string;
  gender: string;
  govId: string;
  password: string;
  confirmPassword: string;
}

export const Signup = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const onSubmit = (data: any) => {
    console.log('Form Submitted:', data);
    navigate('/auth/verify-otp')
  };

  return (
    <Box
      className='flex justify-center items-center w-full'
      minWidth='331px'
    >
      <Box
        className='text-center text-secondary-400 font-medium'
        width={{
          xs: '95%',
          md: '35%',
        }}
        padding={{ xs: '24px', md: '40px' }}
      >
        <Typography
          variant='h5'
          letterSpacing='0.01px'
          fontWeight={500}
        >
          {staticTexts.signUpPageHeaderTextLine1}
        </Typography>
        <Typography
          variant='h5'
          letterSpacing='0.01px'
          fontWeight={500}
        >
          {staticTexts.signUpPageHeaderTextLine2}
        </Typography>
        <Typography
          variant='body1'
          marginTop='12px'
        >
          {staticTexts.findReportAndRecoverItemsEasily}
        </Typography>
        <Box
          className='flex justify-center items-center flex-col'
          marginBottom='32px'
        >
          <Typography
            className='text-sm'
            marginTop='40px'
          >
            {staticTexts.profilePicture}
          </Typography>
          <UploadBox defaultImage={defaultProfileImg} />
          <Typography
            fontSize='12px'
            marginTop='10px'
          >
            {staticTexts.supportedImgFormats}
          </Typography>
        </Box>
       
          <Input
            name='fullName'
            className='!mb-4'
            label={staticTexts.fullName}
            placeholder={staticTexts.enterYourFullName}
            control={control}
            error={errors.fullName}
            isRequired
          />

          <Input
            name='email'
            className='!mb-4'
            label={staticTexts.emailAddress}
            placeholder={staticTexts.enterYourEmail}
            control={control}
            error={errors.email}
            validationRules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email format',
              },
            }}
          />

          <PhoneNumberInput
            name='phone'
            className='!mb-3'
            label='Phone Number'
            helperText={errors.phone?.message || 'You’ll receive a One-Time Password (OTP) for verification.'}
            countryCode='+91'
            control={control}
            error={errors.phone}
            handlePhoneNumberChange={() => {}}
            handleCountryCodeChange={() => {}}
            validationRules={{
              required: 'Phone number is required',
            }}
          />

          <DateInput
            name='dob'
            className='!mb-4'
            label={staticTexts.enterDOB}
            control={control}
            error={errors.dob}
            helperText={errors.dob?.message}
            validationRules={{
              required: 'Date of Birth is required',
            }}
          />

          <Dropdown
            name='gender'
            dropdownStyle={{ marginBottom: '16px' }}
            control={control}
            options={['Male', 'Female']}
            labelText='Select Gender'
            isRequired={true}
            error={errors.gender}
          />

          <Input
            name='govId'
            className='!mb-4'
            label={staticTexts.addGovId}
            placeholder='e.g., DL4567658997'
            control={control}
            error={errors.govId}
            isRequired
          />

          <PasswordInput
            name='password'
            label='Password'
            className='!mb-4'
            control={control}
            error={errors.password}
            validationRules={{
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
            }}
          />

          <PasswordInput
            name='confirmPassword'
            label='Confirm Password'
            className='!mb-4'
            control={control}
            error={errors.confirmPassword}
            validationRules={{
              required: 'Confirm Password is required',
              minLength: {
                value: 6,
                message: 'Password must be at least 6 characters',
              },
            }}
          />

          <Typography
            variant='body1'
            textAlign='left'
            marginTop='24px'
            fontSize='12px'
          >
            {staticTexts.iAgreeTo} <span className='underline'>{staticTexts.termsAndCondition}</span> {staticTexts.and}{' '}
            <span className='underline'>{staticTexts.privacyPolicy}</span>
          </Typography>
          <button
            type='submit'
            className='px-6 mt-10 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
          >
           {staticTexts.createAccount}
          </button>
      
        <p className='text-gray-500 text-xs text-center mt-8'>
          {staticTexts.needHelp} <span className='underline'>{staticTexts.contactSupport}</span>
        </p>
      </Box>
    </Box>
  );
};
