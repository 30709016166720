import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Controller, Control, FieldError } from 'react-hook-form';

interface CustomTextFieldProps extends Omit<TextFieldProps, 'value' | 'onChange' | 'error'> {
  label?: string;
  className?: string;
  isRequired?: boolean;
  name: string;
  control: Control<any>;
  error?: FieldError | undefined;
  validationRules?: Record<string, any>; 
}

export const Input: React.FC<CustomTextFieldProps> = ({
  label,
  className,
  isRequired = false,
  name,
  control,
  validationRules,
  error,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? `${label || 'This field'} is required` : false,
        ...validationRules,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          {...props}
          className={className}
          label={label}
          error={!!error}
          helperText={error?.message || props.helperText }
          fullWidth
          variant="outlined"
        />
      )}
    />
  );
};
