import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Button, PasswordInput } from '../../../../components';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getStaticTexts } from '../../../../assets';
import { useNavigate } from 'react-router-dom';

interface FormFields {
  password: string;
}

const LoginWithPassword = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const handlePasswordLogin = () => {
    navigate('/home');
  };
  return (
    <form onSubmit={handleSubmit(handlePasswordLogin)}>
      <Box
        className='flex flex-col items-center rounded-md'
        marginTop='45px'
      >
        <PasswordInput
          name='password'
          label={staticTexts.password}
          className='!mb-1'
          control={control}
          error={errors.password}
          validationRules={{
            required: `${staticTexts.password} ${staticTexts.isRequired}`,
            minLength: {
              value: 6,
              message: staticTexts.passwordMustBeAtleast6char,
            },
          }}
        />

        <Typography
          className='text-accent w-full text-xs cursor-pointer'
          fontSize='14px'
          marginTop='8px'
          textAlign='right'
          onClick={() => navigate('/auth/forgot-password')}
        >
          {staticTexts.forgotPassword}
        </Typography>

        <Typography
          width='100%'
          fontSize='12px'
          color='#4A403A'
          marginTop='18px'
          textAlign='left'
        >
          {staticTexts.preferLoggingInWithCode}{' '}
          <span
            className='underline text-accent cursor-pointer'
            onClick={() => navigate('/auth/login/otp')}
          >
            {staticTexts.useOTPInstead}
          </span>
        </Typography>

        <Button
          type='submit'
          className='px-6 py-2 rounded-lg w-fit text-center text-sm'
          buttonStyle={{ marginTop: '40px' }}
        >
          {staticTexts.loginWithPassword}
        </Button>
      </Box>
    </form>
  );
};

export default LoginWithPassword;
