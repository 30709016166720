import moment from 'moment';

export const clipText = (text: string, maxLength: number = 20) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

export const formatDate = (timestamp: string) => {
  return moment(Number(timestamp)).format('DD MMM YYYY');
};

