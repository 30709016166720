import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDown';

interface CustomDropdownProps<T> {
  options: T[];
  className?: string;
  name: string;
  control: Control<any>;
  labelText?: string;
  placeholderText?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  dropdownStyle?: Record<string, any>;
  isRequired?: boolean;
  error?: FieldError | undefined;
  validationRules?: Record<string, any>;
}

export const Dropdown = <T,>({
  options,
  className,
  name,
  control,
  labelText,
  placeholderText,
  variant = 'outlined',
  dropdownStyle,
  isRequired = false,
  error,
  validationRules,
  ...autocompleteProps
}: CustomDropdownProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired ? `${labelText || 'This field'} is required` : false,
        ...validationRules,
      }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          disablePortal
          className={className}
          options={options}
          sx={{ ...dropdownStyle }}
          popupIcon={<ArrowDropDownOutlinedIcon />}
          onChange={(_, data) => field.onChange(data)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={labelText}
              placeholder={placeholderText}
              variant={variant}
              error={!!error}
              helperText={error?.message}
            />
          )}
          {...autocompleteProps}
        />
      )}
    />
  );
};
