import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';

interface ImageInputProps {
  helperText?: string;
}
export const ImageUpload: React.FC<ImageInputProps> = ({ helperText }) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      setUploadedFiles((prev) => [...prev, ...newFiles].slice(0, 3)); // Limit to 3 files
    }
  };

  const removeFile = (index: number) => {
    setUploadedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className='rounded-[4px]'>
      {/* Upload Input */}
      <label
        htmlFor='file-upload'
        className='flex items-center justify-between w-full p-3 border-2 border-dashed border-[#776E65] rounded-lg cursor-pointer'
      >
        <span className='text-[#776E65]'>{staticTexts.chooseFile}</span>
        <AddPhotoAlternateIcon sx={{color:'#776E65'}} />
        <input
          id='file-upload'
          type='file'
          className='hidden'
          accept='.png, .jpg, .webp'
          multiple
          onChange={handleFileChange}
        />
      </label>

      {/* File List */}
      <p className='mt-2 text-[12px] ms-4 text-[#93897F]'>{helperText || staticTexts.allowedImageFormatText}</p>
      <ul className='my-4 space-y-2'>
        {uploadedFiles.map((file, index) => (
          <li
            key={index}
            className='flex items-center justify-between p-2 bg-[#F7EFDE] rounded-lg'
          >
            <span className='text-sm text-accent'>{file.name}</span>
            <button
              onClick={() => removeFile(index)}
              className='p-1 text-gray-600 hover:text-gray-900'
            >
              <CloseIcon fontSize='small' />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
