import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { backgroundImage, getStaticTexts, iPhoneImg } from '../../../assets';
import Slider from 'react-slick';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';
import './index.css';

const ProductCard = (props: {
  image: string;
  itemCategory: string;
  itemName: string;
  description: string;
  location: string;
  date: string;
  type: string;
  showDate?: boolean;
  showPrivacy?: boolean;
  showLocation?: boolean;
  showActions?: boolean;
  showClaimButton?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  const {
    itemCategory,
    itemName,
    description,
    location,
    date,
    type,
    showDate = true,
    showPrivacy = true,
    showLocation = true,
    showActions = true,
    showClaimButton = false,
    onEdit,
    onDelete,
  } = props;
  const images = [iPhoneImg, iPhoneImg, backgroundImage];
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    appendDots: (dots: React.ReactNode) => (
      <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: () => <div className='w-2 h-2 bg-white rounded-full opacity-70 hover:opacity-100'></div>,
    dotsClass: 'slick-dots',
  };

  return (
    <Card
      className='rounded-xl overflow-hidden border border-gray-200 p-[2px] w-full md:max-w-[280px] lg:max-w-[256px]'
      sx={{ background: '#F7EFDE', boxShadow: 'none', cursor: 'pointer' }}
      onClick={() => navigate('/item-detail')}
    >
      <Slider
        {...sliderSettings}
        className='carousel-container'
      >
        {images.map((imgSrc, index) => (
          <div key={index}>
            <img
              src={imgSrc}
              alt={`Product image ${index + 1}`}
              className='object-cover h-40 w-full rounded-xl'
            />
          </div>
        ))}
      </Slider>
      <CardContent
        className='px-4 py-2'
        sx={{ padding: '8px 4px' }}
      >
        <Typography
          variant='body1'
          component='p'
          fontSize='12px'
          className='text-secondary-400'
          color='#776E65'
          letterSpacing='-0.01px'

        >
          {itemCategory}
        </Typography>
        <Typography
          variant='body2'
          component='p'
          fontSize='14px'
          color='#4A403A'
        >
          {itemName}
        </Typography>
        <Typography
          variant='body1'
          color='text.secondary'
          className='truncate'
          fontSize='12px'
        >
          {description}
        </Typography>
        {showLocation && (
          <div className='flex items-center mt- space-x-1 text-xs mt-2'>
            <LocationOnIcon sx={{ fontSize: '16px', marginInlineEnd: '4px', color: '#776E65' }} /> {location}
          </div>
        )}

        {showPrivacy && (
          <div className='flex items-center mt-1 space-x-1 text-xs'>
            <LockIcon sx={{ fontSize: '16px', marginInlineEnd: '4px', color: '#776E65' }} /> {type}
          </div>
        )}

        {showDate && (
          <div className='flex items-center mt-1 space-x-1 text-xs'>
            <AccessTimeFilledIcon sx={{ fontSize: '16px', marginInlineEnd: '4px', color: '#776E65' }} /> {date}
          </div>
        )}
      </CardContent>

      {showActions && (
        <div className='flex justify-between px-1 py-2'>
          <div>
            <IconButton
              aria-label='edit'
              size='small'
            >
              <EditIcon fontSize='small' />
            </IconButton>
            <IconButton
              aria-label='delete'
              size='small'
              color='error'
              onClick={onDelete}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          </div>
          <IconButton
            aria-label='more'
            size='small'
            onClick={onEdit}
          >
            <MoreVertIcon fontSize='small' />
          </IconButton>
        </div>
      )}

      {showClaimButton && (
        <div className='flex justify-between px-1 pb-2'>
          <Button
            buttonStyle={{
              background: '#FF6F61',
              width: '100%',
              textAlign: 'center',
              fontWeight: 'normal',
            }}
          >
            {staticTexts.claimItem}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default ProductCard;
