import React, { useMemo } from 'react';
import { Button, ButtonGroup, styled, useMediaQuery } from '@mui/material';
import { Check } from '@mui/icons-material';
import { getStaticTexts } from '../../assets';
import { useSelector } from 'react-redux';

/**
 * Author: Mayank
 * Date: 19 Nov 2024
 *
 * Summary:
 * A customizable toggle button group that allows users to switch between different options.
 * It visually highlights the selected option and triggers a callback when an option is clicked.
 *
 * Description:
 * The `CustomToggle` component displays a group of toggle buttons, where only one option can be selected at a time.
 * The component uses Material-UI's `ButtonGroup` and `Button` components to create the toggle interface, and it applies custom
 * styling to the buttons. When a button is clicked, the `handleToggle` function is called with the key of the selected option.
 * The component also supports dynamic labels fetched from a static texts source (`getStaticTexts`), which makes it easy to
 * manage localized or customizable button labels.
 *
 * Props:
 * - `selected`: The key of the currently selected option. This determines which button is highlighted. (required)
 * - `options`: An array of toggle options. Each option has a `key` (used to identify the option) and a `labelKey`
 *   (which is used to fetch the corresponding label text from the static texts). (required)
 * - `handleToggle`: A function that is called when a button is clicked. It receives the key of the selected option as a parameter. (required)
 *
 * Returns:
 * A group of styled toggle buttons, with the currently selected option visually highlighted and an optional checkmark
 * icon next to it.
 *
 * Usage Example:
 * ```tsx
 * <CustomToggle
 *   selected="option1"
 *   options={[
 *     { key: 'option1', labelKey: 'optionOne' },
 *     { key: 'option2', labelKey: 'optionTwo' }
 *   ]}
 *   handleToggle={(selectedKey) => console.log('Selected:', selectedKey)}
 * />
 * ```
 */

const ToggleButtonGroup = styled(ButtonGroup)({
  borderRadius: '24px',
  overflow: 'hidden',
  boxShadow: 'none',
});

interface ToggleOption {
  key: string;
  labelKey: keyof ReturnType<typeof getStaticTexts>;
  icon?: React.ReactNode; // Optional icon for mobile view
}

interface CustomToggleProps {
  selected: string;
  options: ToggleOption[];
  handleToggle: (option: string) => void;
}

const ToggleButton = styled(Button)<{ selected: boolean }>(({ selected }) => ({
  textTransform: 'none',
  padding: '10px 20px',
  color: selected ? '#5e503f' : '#7a7065',
  backgroundColor: selected ? '#f5e1c7' : '#fdf7e5',
  borderRadius: '24px',
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  '&:hover': {
    backgroundColor: selected ? '#f5e1c7' : '#fdf7e5',
  },
  border: '1px solid #93897F',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));

export const CustomToggle: React.FC<CustomToggleProps> = ({ selected, options, handleToggle }) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust breakpoint as needed

  const maxLabelLength = Math.max(...options.map(({ labelKey }) => staticTexts[labelKey]?.length || 0));
  const buttonWidth =  isMobile ? maxLabelLength * 8 : `${maxLabelLength * 8 + 30}px`;

  return (
    <ToggleButtonGroup>
      {options.map(({ key, labelKey, icon }) => (
        <ToggleButton
          key={key}
          selected={selected === key}
          onClick={() => handleToggle(key)}
          startIcon={selected === key && !isMobile ? <Check /> : null}
          sx={{
            width: {xs:'80px', md:buttonWidth},
            paddingX: { xs: '0px', md:'4px' },
            paddingY: { xs: '4px', md:'8px' },
            fontSize:{xs:'10px', md:'14px'}
          }}
          disableRipple
        >
          {staticTexts[labelKey]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
