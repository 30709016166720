import { Box, IconButton, Menu, MenuItem, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import React, { useReducer } from 'react';
import ViewListIcon from '@mui/icons-material/ViewListRounded';
import GridViewIcon from '@mui/icons-material/GridViewRounded';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, DateInput, Modal } from '../../../components';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { getStaticTexts } from '../../../assets';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { formatDate } from '../../../utils';

interface ControlToolbarProps {
  viewType: string;
  selectedTab: string;
  itemCount: number;
  showButton?: boolean;
  showDateFilter?: boolean;
  onButtonClick?: () => void;
  buttonText?: string;
  onViewChange: (viewType: string) => void;
  handleFilters: () => void;
}

export const ControlToolbar: React.FC<ControlToolbarProps> = ({
  viewType,
  itemCount,
  selectedTab,
  buttonText,
  showButton = true,
  onButtonClick,
  onViewChange,
  handleFilters,
}) => {
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(null);
  const [anchorElDate, setAnchorElDate] = React.useState<null | HTMLElement>(null);
  const [anchorElSort, setAnchorElSort] = React.useState<null | HTMLElement>(null);
  const [openDateRangeModal, setOpendDateRangeModal] = React.useState(false);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<{
    from: string;
    to: string;
  }>();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const onSubmit = () => {
    console.log('called');
  };

  const initialState = {
    dateSortOptions: [
      { label: 'Today', value: moment().startOf('day').toISOString() },
      { label: 'Yesterday', value: moment().subtract(1, 'day').startOf('day').toISOString() },
      { label: 'Last Week', value: moment().subtract(1, 'week').startOf('week').toISOString() },
      { label: 'Last Month', value: moment().subtract(1, 'month').startOf('month').toISOString() },
      { label: 'Last Year', value: moment().subtract(1, 'year').startOf('year').toISOString() },
      { label: 'Custom Date', value: 'custom' },
    ],
    sortOptions: [
      { label: 'Newest → Oldest', value: 'NEWEST' },
      { label: 'Oldest → Newest', value: 'OLDEST' },
      { label: 'Alphabetical (A-Z)', value: 'A-Z' },
      { label: 'Alphabetical (Z-A)', value: 'Z-A' },
    ],
    selectedDateFilter: 'Last Week',
    selectedSort: 'NEWEST',
  };

  const [state, dispatch] = useReducer((state: typeof initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
      case 'UPDATE_STATE':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }, initialState);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  ) => {
    setAnchor(event.currentTarget);
  };

  const handleMenuClose = (setAnchor: React.Dispatch<React.SetStateAction<null | HTMLElement>>) => {
    setAnchor(null);
  };

  const handleDateFilterChange = (option: { label: string; value: string }) => {
    if(option.value === 'custom') {
      setOpendDateRangeModal(true)
    }
    dispatch({ type: 'UPDATE_STATE', payload: { selectedDateFilter: option.label } });
    handleMenuClose(setAnchorElDate);
  };

  const handleSortChange = (sortValue: string) => {
    dispatch({ type: 'UPDATE_STATE', payload: { selectedSort: sortValue } });
    handleMenuClose(setAnchorElSort);
  };

  return (
    <Box className='flex items-center justify-between pt-6 px-2 pb-2 min-w-16'>
      {/* Title and Count */}
      <Box className='flex items-center space-x-4'>
        <Typography
          variant='h6'
          className='text-2xl font-semibold'
        >
          {selectedTab}
        </Typography>
      </Box>

      {/* View Options and Report Button */}
      <Box className='hidden md:flex items-center space-x-2'>
        <span className='text-secondary-200 text-sm'>{itemCount} lost items</span>

        {/* View Dropdown */}
        <IconButton
          className='border border-blue-500'
          size='small'
          disableRipple
          onClick={(event) => handleMenuClick(event, setAnchorElView)}
        >
          {state.view === 'Grid' ? <GridViewIcon /> : <ViewListIcon />}
          <span className='ms-1 text-sm'>{viewType}</span>
          <ArrowDownRoundedIcon fontSize='small' />
        </IconButton>
        <Menu
          anchorEl={anchorElView}
          open={Boolean(anchorElView)}
          onClose={() => handleMenuClose(setAnchorElView)}
        >
          <MenuItem
            onClick={() => {
              onViewChange('Grid');
              handleMenuClose(setAnchorElView);
            }}
          >
            Grid
          </MenuItem>
          <MenuItem
            onClick={() => {
              onViewChange('List');
              handleMenuClose(setAnchorElView);
            }}
          >
            List
          </MenuItem>
        </Menu>

        {/* Date Filter Dropdown */}
        <IconButton
          className='border border-blue-500'
          size='small'
          disableRipple
          onClick={(event) => handleMenuClick(event, setAnchorElDate)}
        >
          <CalendarMonthIcon />
          <span className='ms-1 text-sm'>{state.selectedDateFilter}</span>
          <ArrowDownRoundedIcon fontSize='small' />
        </IconButton>
        <Menu
          anchorEl={anchorElDate}
          open={Boolean(anchorElDate)}
          onClose={() => handleMenuClose(setAnchorElDate)}
        >
          {state.dateSortOptions.map((option: { value: any; label: any }) => (
            <MenuItem
              key={option.value}
              onClick={() => handleDateFilterChange(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>

        {/* Sort Dropdown */}
        <IconButton
          className='border border-blue-500 !text-sm'
          size='small'
          onClick={(event) => handleMenuClick(event, setAnchorElSort)}
          disableRipple
        >
          Sort
          <ArrowDownRoundedIcon fontSize='small' />
        </IconButton>
        <Menu
          anchorEl={anchorElSort}
          open={Boolean(anchorElSort)}
          onClose={() => handleMenuClose(setAnchorElSort)}
        >
          <RadioGroup
            sx={{
              '& .MuiButtonBase-root-MuiRadio-root': {
                paddingInlineStart: 0,
              },
            }}
            // value={selectedSort}
            //  onChange={(e) => handleSelect(e.target.value)}
          >
            {state.sortOptions.map(
              (option: {
                value: string;
                label:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | null
                  | undefined;
              }) => (
                <MenuItem
                  key={option.value}
                  disableRipple
                >
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    style={{ margin: 0, textAlign: 'left' }}
                  />
                </MenuItem>
              )
            )}
          </RadioGroup>
        </Menu>

        {/* Report Lost Item Button */}

        {showButton && (
          <Button
            variant='contained'
            size='small'
            buttonStyle={{
              background: '#FF6F61',
              display: 'inline-flex',
              fontWeight: 'normal',
            }}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
      <IconButton
        className='block md:!hidden'
        size='small'
        disableRipple
        onClick={handleFilters}
      >
        <FilterAltIcon />
      </IconButton>

      <Modal
        open={openDateRangeModal}
        onClose={() =>  setOpendDateRangeModal(false)}
        onConfirm={() => {
          if(getValues('from') && getValues('to')) {
            const from = formatDate(getValues('from'));
            const to = formatDate(getValues('to'));
            dispatch({ type: 'UPDATE_STATE', payload: { selectedDateFilter: from + ' → ' + to } });
          }
          setOpendDateRangeModal(false);
        }}
        confirmText='Apply Filter'
        cancelText='Cancel'
        customStyles={{
          width: '412px',
          bgcolor: '#FFF6E4',
          textAlign: 'center',
          borderRadius: '12px',
          padding: '24px',
        }}
        buttonContainerStyle={{ justifyContent: 'space-between' }}
        cancelButtonStyle={{ color: '#FF6F61', paddingInlineStart: '0px', justifySelf: 'self-start' }}
      >
        <Box position='relative'>
          <IconButton
            aria-label='close'
            onClick={() =>  setOpendDateRangeModal(false)}
            edge='end'
            style={{
              marginLeft: 'auto',
              position: 'absolute',
              right: '-2px',
              top: '-16px',
              backgroundColor: '#FFE0B5',
              zIndex: 1,
              height: '28px',
              width: '28px',
            }}
          >
            <CloseIcon sx={{ fontSize: '18px' }} />
          </IconButton>
          <Typography
            variant='body2'
            component='p'
            letterSpacing='0.01px'
            fontWeight={400}
            fontSize='22px'
            textAlign='left'
          >
            {staticTexts.filterDateByRange}
          </Typography>
          <Typography
            variant='body1'
            fontSize='14px'
            sx={{ color: '#655B53FA' }}
          >
            {staticTexts.filterYourDataByChoosingCustomDateRange}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: '24px' }}>
              <DateInput
                name='from'
                className='w-1/2'
                label='From'
                control={control}
                error={errors.to}
                helperText={errors.to?.message}
                validationRules={{
                  required: 'To date is required',
                }}
              />
              <DateInput
                name='to'
                className='w-1/2'
                label='To'
                control={control}
                error={errors.to}
                helperText={errors.to?.message}
                validationRules={{
                  required: 'To date is required',
                }}
              />
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};
