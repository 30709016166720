import { useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

interface UploadBoxProps {
    defaultImage: string;
  }
  
export const UploadBox: React.FC<UploadBoxProps> = ({ defaultImage }) => {
    const [image, setImage] = useState<string | null>(null);
    const [hovered, setHovered] = useState(false);
  
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setImage(event.target?.result as string);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  
    const handleDeleteImage = () => {
      setImage(null);
    };
  
    return (
      <div
        className="relative w-20 h-20 rounded-full overflow-hidden cursor-pointer border border-[#C9C2B4]"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <label
          htmlFor="imageUpload"
          className="w-full h-full flex items-center justify-center"
        >
          {/* Show default image or uploaded image */}
          <img
            src={image || defaultImage}
            alt="profile-img"
            className="w-full h-full object-cover"
          />
  
          {/* Overlay for hover icon */}
          {hovered && (
            <div className="absolute inset-0 bg-black bg-opacity-1 flex items-center justify-center">
              {image ? (
                <DeleteIcon
                  className="text-white"
                  onClick={handleDeleteImage}
                />
              ) : (
                <AddPhotoAlternateIcon className="text-white" />
              )}
            </div>
          )}
        </label>
  
        {/* Hidden input for file upload */}
        <input
          id="imageUpload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="hidden"
        />
      </div>
    );
  };
