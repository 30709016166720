import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

// Styled Green Dot Badge (Top-Left)
const StyledBadge = styled('span')(({ theme }) => ({
  backgroundColor: '#FF6F61',
  width: 10,
  height: 10,
  borderRadius: '50%',
  position: 'absolute',
  top: '8px',
  left: '2px',
  zIndex:1,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  transform: 'translate(-50%, -50%)',
}));

// Styled Small Avatar (Bottom-Right)
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  position: 'absolute',
  bottom: '8px',
  right: '2px',
  zIndex:1,
  transform: 'translate(50%, 50%)',
}));

// Main Avatar Container (Parent Positioning)
const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

export const BadgeAvatars = () => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <AvatarContainer>
        {/* Green Dot (Top-Left) */}
        <StyledBadge />
        {/* Small Avatar (Bottom-Right) */}
        <SmallAvatar alt="Small Avatar" src="/static/images/avatar/1.jpg" />
        {/* Main Avatar */}
        <Avatar alt="Main Avatar" src="/static/images/avatar/2.jpg" />
      </AvatarContainer>
    </Stack>
  );
};
