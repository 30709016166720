import { Box, Typography } from '@mui/material';
import React from 'react';
import { Button, DateInput, ImageUpload, Input } from '../../components';
import { CheckBox } from '@mui/icons-material';
import { getStaticTexts, iPhoneImg } from '../../assets';
import LocationIcon from '@mui/icons-material/LocationOn';
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface FormFields {
  fullName: string;
  email: string;
  phone: string;
  date: string;
  description: string;
}
const ClaimItem = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);

  const onSubmit = (data: any) => {
    console.log('Form Submitted:', data);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        bgcolor='#FFF7E6'
        paddingX='32px'
        paddingY='40px'
        width='50%'
        borderRadius='8px'
        marginTop='64px'
      >
        <Typography
          component='p'
          fontSize='26px'
          color='#4A403A'
          letterSpacing='0.01px'
        >
          {staticTexts.claimItem}
        </Typography>

        <Box
          bgcolor='#F7EFDE'
          borderRadius='14px'
          marginTop='16px'
          marginBottom='24px'
          padding='8px'
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Box
            height='64px'
            width='64px'
            borderRadius='6px'
          >
            <img
              className='rounded-md'
              src={iPhoneImg}
              alt='item-image'
              height='100%'
              width='100%'
            />
          </Box>

          <Box>
            <Typography
              component='p'
              fontSize='14px'
              color='#4A403A'
              marginTop='8px'
            >
              iPhone 11
            </Typography>

            <Typography
              component='p'
              fontSize='12px'
              color='#655B53FA'
            >
              Black wallet with driver’s license and credit cards.
            </Typography>

            <Typography
              component='p'
              fontSize='12px'
              color='#655B53FA'
              display='flex'
              alignItems='center'
              gap='8px'
            >
              <LocationIcon sx={{ fontSize: '12px', color: '#655B53FA' }} /> Downtown Café, 5th Avenue
            </Typography>
          </Box>
        </Box>
        <Box
          bgcolor='#FFE0B5'
          padding='8px'
          borderRadius='8px'
          color='#655B53FA'
          marginBottom='24px'
          fontSize='12px'
        >
          <DirectionsWalkRoundedIcon
            sx={{ fontSize: '14px' }}
            className='me-1 text-secondary-100'
          />
          {staticTexts.claimNote}
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name='description'
            className='!mb-6'
            label={staticTexts.pleaseStateWhyThisItemIsYours}
            placeholder={staticTexts.describeAnyUnique}
            fullWidth
            multiline
            rows={4}
            control={control}
            error={errors.description}
            isRequired
            helperText={staticTexts.claimDescription}
          />
          <Input
            name='fullName'
            className='!mb-6'
            label={staticTexts.fullName}
            control={control}
            error={errors.fullName}
            isRequired
          />

          <Input
            name='email'
            className='!mb-6'
            label={staticTexts.emailAddress}
            control={control}
            error={errors.email}
            validationRules={{
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email format',
              },
            }}
          />

          <DateInput
            name='Date'
            className='!mb-1'
            label={staticTexts.selectDate}
            control={control}
            error={errors.date}
            helperText={errors.date?.message || ' '}
            validationRules={{
              required: 'Date is required',
            }}
          />
          <Typography
            marginBottom='8px'
            color='#655B53'
            fontSize='14px'
          >
            {staticTexts.uploadImages}
          </Typography>
          <ImageUpload helperText={staticTexts.claimItemImageHelperText} />
          <Box
            display='flex'
            gap='12px'
            alignItems={'center'}
          >
            <CheckBox className='text-accent' />
            <Typography
              component='p'
              fontSize='12px'
              color='#776E65'
            >
              {staticTexts.iAgreeTo} {staticTexts.termsAndCondition}
            </Typography>
          </Box>

          <Box
            display='flex'
            justifyContent='space-between'
            marginTop='24px'
          >
            <Button
              variant='text'
              buttonStyle={{ color: '#FF6F61', paddingInlineStart: '0px', justifyContent: 'flex-start' }}
            >
              {staticTexts.back}
            </Button>
            <Button
              buttonStyle={{ background: '#FF6F61' }}
              variant='contained'
              type='submit'
            >
              {staticTexts.submit}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ClaimItem;
