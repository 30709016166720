import React, { useEffect, useState } from 'react';
import { Search, MyLocation } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dialog } from '../../Dialog';
import { GoogleMap } from '../../GoogleMap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../../assets';
import { Menu, MenuItem, useMediaQuery } from '@mui/material';

interface SearchBarProps {
  handleFocus?: () => void;
}
export const SearchBar: React.FC<SearchBarProps> = ({ handleFocus }) => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const [state, setState] = useState({
    location: '',
    category: staticTexts.category,
    isDialogOpen: false,
  });
  const isMobile = useMediaQuery("(max-width: 768px)");


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number; description: string } | null>(
    null
  );

  const handleLocationSelect = (location: { lat: number; lng: number; description: string }) => {
    console.log('Selected Location:', location);
    setSelectedLocation(location);
    closeDialog();
  };

  const handleInputChange = (field: 'location' | 'category', value: string) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const openDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: true,
    }));
  };

  const closeDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: false,
    }));
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const options = [
    { label: 'Automobile', value: 'automobile' },
    { label: 'Footwear', value: 'footwear' },
    { label: 'Electronics', value: 'electronics' },
    { label: 'Clothing', value: 'clothing' },
  ];

  const handleOptionSelect = (option: any) => {
    setState((prev) => ({
      ...prev,
      category: option,
    }));
    handleMenuClose();
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <div className='flex items-center justify-center rounded-lg flex-wrap bg-[#FFF7E6]'>
      <input
        type='text'
        placeholder={staticTexts.searchLostItems}
        className='px-4 py-2 lg:rounded-l-lg rounded focus:outline-none bg-transparent'
        onFocus={()=>{
          isMobile && navigate('/search-items')
        }}
      />

      <div className='relative lg:flex items-center hidden'>
        <input
          type='text'
          value={selectedLocation?.description || ''}
          placeholder={staticTexts.location}
          className='px-4 py-2 pr-8 focus:outline-none bg-transparent'
          readOnly
        />
        <MyLocation
          sx={{ fontSize: '14px' }}
          className='absolute right-2 text-accent text-sm cursor-pointer'
          onClick={openDialog}
        />
      </div>

      <div className='relative px-1 lg:flex rounded-r-lg justify-center items-center'>
        <button
          className='lg:flex hidden items-center px-4 py-2 focus:outline-none'
          onClick={handleMenuOpen}
        >
          {state.category}
          <span className='ml-2'>
            <KeyboardArrowDownIcon />
          </span>
        </button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => handleOptionSelect(option.label)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <button
          onClick={() => navigate('/home/search')}
          className='bg-[#EC2A3B] text-white px-4 py-2 rounded-full h-8 w-8 flex justify-center items-center'
        >
          <Search />
        </button>
      </div>

      <Dialog
        open={state.isDialogOpen}
        content={<GoogleMap onLocationSelect={handleLocationSelect} />}
        onClose={closeDialog}
      />
    </div>
  );
};
