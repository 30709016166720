import React, { useState } from 'react';
import { Box, Avatar, TextField, Button, Typography, MenuItem, IconButton } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export const BusinessProfile = () => {
  const [coverPhoto, setCoverPhoto] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');

  // Function to handle cover photo upload
  const handleCoverPhotoChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setCoverPhoto(url);
    }
  };

  // Function to handle profile photo upload
  const handleProfilePhotoChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setProfilePhoto(url);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: 'auto',
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: 3,
        backgroundColor: '#fff8e1',
        marginTop: '62px',
      }}
    >
      {/* Editable Cover Photo */}
      <Box
        sx={{
          height: 150,
          background: coverPhoto
            ? `url(${coverPhoto}) no-repeat center/cover`
            : 'linear-gradient(to bottom, #FFE0B5, #FAB98A)',
          position: 'relative',
        }}
      >
        <input
          hidden
          accept='image/*'
          type='file'
          onChange={handleCoverPhotoChange}
        />
      </Box>

      {/* Editable Profile Picture */}
      <Box
        sx={{
          position: 'relative',
          top: -40,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            border: '4px solid #fff',
            position: 'relative',
          }}
          src={profilePhoto}
        />
      </Box>

      {/* Profile Details */}
      <Box sx={{ p: 3 }}>
        <TextField
          fullWidth
          label='Organization Name'
          defaultValue='Vertex Solutions'
          variant='outlined'
          margin='normal'
        />
        <TextField
          fullWidth
          label='Organization Address'
          defaultValue='1234 Elm Street, Suite 567, San Francisco, CA 94108 United States'
          variant='outlined'
          margin='normal'
        />
        <TextField
          fullWidth
          label='Email Address'
          defaultValue='care@vertex.com'
          variant='outlined'
          margin='normal'
        />
        <TextField
          fullWidth
          select
          label='Preferred Language'
          defaultValue='English'
          variant='outlined'
          margin='normal'
        >
          <MenuItem value='English'>English</MenuItem>
          <MenuItem value='Spanish'>Spanish</MenuItem>
          <MenuItem value='French'>French</MenuItem>
        </TextField>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            variant='contained'
            color='primary'
            sx={{
              backgroundColor: '#FF6F61',
              ':hover': {
                backgroundColor: '#FF6F61',
              },
            }}
          >
            Edit Profile
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
