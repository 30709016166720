import { Fragment, useReducer, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, PhoneNumberInput } from '../../../components';
import { getStaticTexts } from '../../../assets';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

interface FormFields {
  phoneNumber: string;
}

const initialState = {
  loginType: '',
  countryCode: '+91',
  phoneNumber: '',
  submitType: '',
};

type Action =
  | { type: 'LOGIN_TYPE'; payload: string }
  | { type: 'UPDATE_FIELDS'; payload: any }
  | { type: 'PHONE_NUMBER_INPUT'; payload: string };

const loginReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'UPDATE_FIELDS':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const Login = () => {
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), [language]);
  const { pathname } = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const handleLogin = useCallback(
    (data: object, event: any) => {
      const submitType = event.nativeEvent.submitter.value;
      switch (submitType) {
        case 'otp':
          navigate('/auth/login/otp');
          break;
        case 'password':
          navigate('/auth/login/password');
          break;
        default:
          navigate('/auth/login/password');
          break;
      }
    },
    [navigate]
  );

  const getHeaderText = useCallback(
    (type: string) => (type === 'forgot_password' ? staticTexts.forgotPassword : staticTexts.welcomeText),
    [staticTexts]
  );

  const getSubText = useCallback(
    (type: string) =>
      type === 'forgot_password' ? (
        <Fragment>
          <p>{staticTexts.enterYourRegisteredMobileNumber}</p>
          <p>{staticTexts.aResetCode}</p>
        </Fragment>
      ) : (
        staticTexts.findReportAndRecoverItemsEasily
      ),
    [staticTexts]
  );

  const showForm = !['password', 'otp'].includes((pathname || '').split('/').pop() || '');

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md mt-16 px-4'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <Typography
            variant='body2'
            component='p'
            fontSize='26px'
            color='#4A403A'
            letterSpacing='0.01px'
          >
            {getHeaderText(state.loginType)}
          </Typography>
          <Typography
            variant='body1'
            component='p'
            fontSize='16px'
            color='#655B53FA'
            className='text-secondary-200 text-sm mt-2'
          >
            {getSubText(state.loginType)}
          </Typography>
        </Box>

        {showForm && (
          <form
            className='w-full'
            onSubmit={handleSubmit(handleLogin)}
          >
            <Box className='mt-6'>
              <Box className='flex items-center rounded-md py-2'>
                <PhoneNumberInput
                  name='phoneNumber'
                  label={staticTexts.phoneNumber}
                  helperText={errors.phoneNumber?.message || staticTexts.youWillReceiveOTPforVerification}
                  countryCode={state.countryCode}
                  control={control}
                  error={errors.phoneNumber}
                  handleCountryCodeChange={() => {}}
                  helperTextColor='#776E65'
                  validationRules={{
                    required: `${staticTexts.phoneNumber} ${staticTexts.isRequired}`,
                  }}
                />
              </Box>
              <Typography
                component='div'
                fontSize='12px'
                color='#776E65'
                marginTop='16px'
              >
                {staticTexts.byLogginInYouWillAgreeToOur}{' '}
                <span
                  className='underline cursor-pointer'
                  onClick={() => navigate('/terms-and-condition')}
                >
                  {staticTexts.termsOfService}
                </span>
                <span className='mx-1'>{staticTexts.and}</span>
                <span
                  className='underline cursor-pointer'
                  onClick={() => navigate('/privacy-policy')}
                >
                  {staticTexts.privacyPolicy}
                </span>
              </Typography>
            </Box>

            <Box className='flex gap-4 mt-8 justify-center items-center'>
              <Button
                type='submit'
                variant='outlined'
                className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
                name='submitType'
                value='password'
              >
                {staticTexts.loginWithPassword}
              </Button>

              <Button
                type='submit'
                className='px-6 py-2 rounded-lg w-fit text-center text-sm'
                name='submitType'
                value='otp'
              >
                {staticTexts.continueWithOTP}
              </Button>
            </Box>
          </form>
        )}
        <Outlet />
        <Typography
          variant='body1'
          fontSize='12px'
          color='#776E65'
          marginTop='40px'
          className='text-center cursor-pointer'
          onClick={() => navigate('/auth/signup')}
        >
          {staticTexts.needHelp} <span className='underline'>{staticTexts.contactSupport}</span>
        </Typography>
      </Box>
    </Fragment>
  );
};
