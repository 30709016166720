import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Button, DateInput, Dropdown, Input, UploadBox } from '../../components';
import { defaultProfileImg } from '../../assets';
import { useForm } from 'react-hook-form';

export const Profile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<{
    fullName: string;
    email: string;
    dob: string;
    language: string;
    gender: string;
  }>();

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  const onSubmit = (data: any) => {
    console.log('Form Submitted:', data);
  };

  return (
    <Box className='h-screen'>
      <p className='m-auto w-1/2 py-8 font-medium text-2xl leading-8 tracking-tight text-gray-900 opacity-100 text-left'>
        Edit Profile
      </p>

      <Box className='m-auto bg-[#FFF7E6] rounded-lg p-8 w-1/2' minWidth='331px'>
        <Box className='flex justify-center items-center flex-col'>
          <Typography
            className='text-sm'
            marginTop='40px'
          >
            Profile Picture
          </Typography>
          <UploadBox defaultImage={defaultProfileImg} />
          <Typography
            fontSize='12px'
            marginTop='10px'
            marginBottom='32px'
          >
            * PNG, JPG & HEIF formats, up to 5MB.
          </Typography>
        </Box>
        {/* Render single fields */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name='fullName'
            className='!mb-6'
            label='Full Name'
            placeholder='Enter your full name'
            control={control}
            error={errors.fullName}
            isRequired
          />

          <Input
            name='fullName'
            className='!mb-6'
            label='Email'
            placeholder='Enter your email address'
            control={control}
            error={errors.fullName}
            isRequired
          />

          <Box
            className='w-full'
            display={{
              xs: 'block', md: 'flex'
            }}
            marginBottom={{
              xs:'24px', md:'12px'
            }}
            gap='8px'
          >
            <Box className='md:w-1/2 w-full'>
              <DateInput
                name='dob'
                className='!w-full'
                label='Enter DOB (Date of Birth)'
                control={control}
                error={errors.dob}
                helperText={errors.dob?.message || ' '}
                validationRules={{
                  required: 'Date of Birth is required',
                }}
              />
            </Box>

            <Box className='md:w-1/2 w-full'>
              <Dropdown
                name='gender'
               // dropdownStyle={{ marginBottom: '4px' }}
                control={control}
                options={['Male', 'Female']}
                labelText='Select Gender'
                isRequired={true}
                error={errors.gender}
              />
            </Box>
          </Box>
          <Dropdown
            name='language'
           // dropdownStyle={{ marginBottom: '4px' }}
            control={control}
            options={['Arablic', 'English']}
            labelText='Prefered Language'
            isRequired={true}
            error={errors.language}
          />

        <Box className='flex justify-between mt-10'>
          <Button
            variant='text'
            buttonStyle={{ color: '#FF6F61', display: isEdit ? 'block' : 'none', flexGrow: 0 }}
          >
            Cancel
          </Button>
          <Button
            buttonStyle={{ background: '#FF6F61', padding: '8px', marginLeft: 'auto' }}
            onClick={handleClick}
            type='submit'
          >
            {isEdit ? 'Save Changes' : 'Edit'}
          </Button>
        </Box>
        </form>
      </Box>
    </Box>
  );
};
