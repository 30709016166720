import React, { useState, MouseEvent, memo } from 'react';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller, Control, FieldError } from 'react-hook-form';

interface PasswordInputProps {
  label?: string;
  fullWidth?: boolean;
  width?: string | number;
  className?: string;
  name: string;
  control: Control<any>;
  error?: FieldError | undefined;
  validationRules?: Record<string, any>;
  direction?: 'ltr' | 'rtl';
  helperText?: string;
}

const PasswordInputComponent: React.FC<PasswordInputProps> = ({
  label = 'Password',
  className,
  fullWidth = true,
  width = '25ch',
  name,
  control,
  error,
  validationRules,
  direction = 'ltr',
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      render={({ field }) => (
        <FormControl
          sx={{
            width: fullWidth ? '100%' : width,
            direction: direction,
          }}
          className={className}
          variant='outlined'
        >
          <InputLabel
            htmlFor={`${name}-password`}
            sx={{
              right: direction === 'rtl' ? 0 : 'auto',
              left: direction === 'rtl' ? 'auto' : 0,
            }}
          >
            {label}
          </InputLabel>
          <OutlinedInput
            {...field}
            id={`${name}-password`}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                  className='text-sm'
                  sx={{
                    marginLeft: direction === 'rtl' ? '-8px' : '8px', // Adjust for RTL
                    marginRight: direction === 'rtl' ? '8px' : '-8px',
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
            error={!!error}
            sx={{
              textAlign: direction === 'rtl' ? 'right' : 'left',
            }}
          />
          <FormHelperText error={!!error}>{error?.message || helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const PasswordInput = memo(PasswordInputComponent);
