import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Footer, Header } from '../../components';

/**
 * Author: Mayank
 * Date: 27 Nov 2024
 * Summary: Layout component serves as the wrapper for all pages.
 * Description:
 * - It includes the following components:
 * 1. The Header component that typically displays the navigation bar or top section of the page.
 * 2. The main content area where the child routes are rendered using the `Outlet` component from `react-router-dom`.
 * 3. The Footer component that appears at the bottom of the page with site-wide information or links.
 */
export const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/auth/login');
    } else {
      navigate('/home');
    }
  }, []);

  return (
    <>
      <Header />
      <div className='min-w-96'>
        <Outlet />
      </div>
      {!['search-items', 'chat'].includes(pathname.split('/').pop() || '') && <Footer />}
    </>
  );
};
