import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, CustomToggle, ProductListItem } from '../../../components';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Search } from '@mui/icons-material';
import { iPhoneImg } from '../../../assets';

const SearchInput = () => {
  return (
    <div className="relative w-1/2 rounded-lg border border-[#C9C2B4]">
      <input
        type="text"
        placeholder="Search"
        className="w-full ps-10 pr-4 py-2 rounded-lg border-none outline-none bg-[#FFF7E6]"
      />
      <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#776E65]">
        <Search />
      </span>
    </div>
  );
};
const ClaimRequests = () => {
  const [selected, setSelected] = useState('Pending');
  const products = [
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'Iphone 6',
      description: 'New Apple iPhone 16 Pro, 256GB.',
      location: 'Mohali',
      date: '10 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'Samsung Galaxy',
      description: 'Samsung Galaxy S21, 128GB.',
      location: 'Chandigarh',
      date: '12 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Accessory',
      itemName: 'Black Leather Wallet',
      description: "Wallet with driver's license and cards.",
      location: 'Downtown Café, 5th Avenue',
      date: '13 Oct 2024',
      type: 'Public',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Accessory',
      itemName: 'Ray-Ban Sunglasses',
      description: 'Classic Ray-Ban sunglasses.',
      location: 'Park Mall',
      date: '15 Oct 2024',
      type: 'Public',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'AirPods Pro',
      description: 'Apple AirPods Pro, with case.',
      location: 'City Center',
      date: '17 Oct 2024',
      type: 'Private',
    },
  ];
  const handleToggle = (option: string) => {
    setSelected(option);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography
        textAlign="left"
        component="p"
        className="w-1/2"
        marginTop="68px"
        fontSize="26px"
        letterSpacing="0.01px"
        color="#4A403A"
      >
        Claim Requests
      </Typography>

      <Box
        marginTop="40px"
        width="50%"
        bgcolor="#FFF7E6"
        display="flex"
        justifyContent="start"
        alignItems="center"
        borderRadius="8px"
        flexDirection="column"
        padding="32px"
        boxShadow="0px 1px 1px #0000000D"
        height="800px"
      >
        <CustomToggle
          selected={selected}
          options={[
            { key: 'Pending', labelKey: 'pending' },
            { key: 'Accepted', labelKey: 'accepted' },
            { key: 'Rejected', labelKey: 'rejected' },
          ]}
          handleToggle={handleToggle}
        />

        <Typography
          textAlign="left"
          component="p"
          className="w-full"
          marginTop="24px"
          fontSize="22px"
          letterSpacing="0.01px"
          color="#4A403A"
          marginBottom="16px"
        >
          Claim Requests
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <SearchInput />

          <Box
            display="flex"
            alignItems="center"
          >
            <span className="text-secondary-200 text-sm me-4">{200} lost items</span>
            <FilterAltIcon sx={{ color: '#776E65' }} />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          marginTop="16px"
          width="100%"
        >
          {products.map((product, index) => (
            <ProductListItem
              key={index}
              title={product.itemName}
              description={product.description}
              location={product.location}
              visibility={product.type}
              date={product.date}
              imageUrl={product.image}
              showDate={false}
              showLocation={false}
              showVisibility={false}
              //showActions={false}
              onEdit={() => console.log('Edit clicked')}
              onDelete={() => console.log('Delete clicked')}
              customActions={
                <Box>
                  <Button
                    className='me-4'
                    variant="text"
                    buttonStyle={{ color: '#655B53FA' }}
                  >
                    <span className="underline">View</span>
                  </Button>
                  <Button
                    className='me-2'
                    variant="outlined"
                    buttonStyle={{border: '1px solid #C9C2B4', color:'#655B53FA', marginInlineEnd: '8px'}}
                  >
                    Decline
                  </Button>
                  <Button
                    variant="contained"
                    buttonStyle={{background: '#FF6F61', color:'#FFF7E6'}}
                  >
                    Accept
                  </Button>
                </Box>
              }
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimRequests;
