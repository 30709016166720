import React, { Fragment, useEffect, useState } from 'react';
import { Search, MyLocation } from '@mui/icons-material';
import { Button, ControlToolbar, GoogleMap, ProductListItem } from '../../../../components';
import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { iPhoneImg } from '../../../../assets';
import ProductCard from '../../../../components/Layouts/ProductCard';
import FilterDrawer from '../../../../components/Layouts/FilterDrawer';
import { Dialog } from '../../../../components/Dialog';
import { clipText } from '../../../../utils';

interface Category {
  id: string;
  name: string;
}

const products = [
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Iphone 6',
    description: 'New Apple iPhone 16 Pro, 256GB.',
    location: 'Mohali',
    date: '10 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Samsung Galaxy',
    description: 'Samsung Galaxy S21, 128GB.',
    location: 'Chandigarh',
    date: '12 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Black Leather Wallet',
    description: "Wallet with driver's license and cards.",
    location: 'Downtown Café, 5th Avenue',
    date: '13 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Ray-Ban Sunglasses',
    description: 'Classic Ray-Ban sunglasses.',
    location: 'Park Mall',
    date: '15 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'AirPods Pro',
    description: 'Apple AirPods Pro, with case.',
    location: 'City Center',
    date: '17 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Iphone 6',
    description: 'New Apple iPhone 16 Pro, 256GB.',
    location: 'Mohali',
    date: '10 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'Samsung Galaxy',
    description: 'Samsung Galaxy S21, 128GB.',
    location: 'Chandigarh',
    date: '12 Oct 2024',
    type: 'Private',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Black Leather Wallet',
    description: "Wallet with driver's license and cards.",
    location: 'Downtown Café, 5th Avenue',
    date: '13 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Accessory',
    itemName: 'Ray-Ban Sunglasses',
    description: 'Classic Ray-Ban sunglasses.',
    location: 'Park Mall',
    date: '15 Oct 2024',
    type: 'Public',
  },
  {
    image: iPhoneImg,
    itemCategory: 'Electronic',
    itemName: 'AirPods Pro',
    description: 'Apple AirPods Pro, with case.',
    location: 'City Center',
    date: '17 Oct 2024',
    type: 'Private',
  },
];

const SearchItems = () => {
  const [viewType, setViewType] = useState('Grid');
  const [open, setOpen] = useState(false);
  const handleViewTypeChange = (value: string) => {
    setViewType(value);
  };

  const toggleDrawer = (state: boolean) => {
    console.log('state', state);
    setOpen(state);
  };

  return (
    <Box className='w-full'>
      <Box
        className='mx-auto'
        width={{
          xs: '92%',
          md: '80%',
        }}
        minHeight='700px'
      >
        <ControlToolbar
          selectedTab='Wallet in France'
          viewType='Grid'
          onViewChange={handleViewTypeChange}
          itemCount={100}
          showButton={false}
          handleFilters={() => setOpen(true)}
        />

        <Box className='w-full'>
          {viewType === 'List' ? (
            products.map((product, index) => (
              <div className='mb-2'>
                <ProductListItem
                  key={index}
                  title={product.itemName}
                  description={product.description}
                  location={product.location}
                  visibility={product.type}
                  date={product.date}
                  imageUrl={product.image}
                  showActions={false}
                  showClaimButton={true}
                  onDelete={() => console.log('Delete clicked')}
                />
              </div>
            ))
          ) : (
            <div
              className='grid gap-4 place-items-center'
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
                gap: '1rem',
              }}
            >
              {products.map((product, index) => (
                <ProductCard
                  key={index}
                  image={product.image}
                  itemCategory={product.itemCategory}
                  itemName={product.itemName}
                  description={product.description}
                  location={product.location}
                  date={product.date}
                  type={product.type}
                  showActions={false}
                  showPrivacy={false}
                  showClaimButton={true}
                />
              ))}
            </div>
          )}
        </Box>
        <FilterDrawer
          onApply={() => {}}
          isOpen={open}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    </Box>
  );
};

const categories: Category[] = [
  { id: 'all', name: 'All Categories' },
  { id: 'electronics', name: 'Electronics' },
  { id: 'clothing', name: 'Clothing' },
  { id: 'accessories', name: 'Accessories' },
  { id: 'documents', name: 'Documents' },
];

export const SearchMobile: React.FC = () => {
  const [searchItem, setSearchItem] = useState('');
  const [location, setLocation] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>(categories[0]);
  const [showSearchOptions, setShowSearchOptions] = useState(true);
  const [showProductListing, setShowProductListing] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number; description: string } | null>(
    null
  );
  const [state, setState] = useState({
    isDialogOpen: false,
  });
  const navigate = useNavigate();

  const closeDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: false,
    }));
  };

  const openDialog = () => {
    setState((prev) => ({
      ...prev,
      isDialogOpen: true,
    }));
  };

  const handleLocationSelect = (location: { lat: number; lng: number; description: string }) => {
    console.log('Selected Location:', location);
    setLocation(clipText(location?.description,15))
    setSelectedLocation(location);
    closeDialog();
  };

  const handleSearch = () => {
    // Implement search functionality
    console.log('Searching:', { searchItem, location, category: selectedCategory });
    setShowSearchOptions(false);
    setShowProductListing(true);
  };

  const handleUseCurrentLocation = () => {
    // Implement geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation('Current Location');
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    }
  };

  // useEffect(()=>{
  //   navigate('/home/search')
  // })

  return (
    <Fragment>
      <div className='bg-[#ECE4D5]'>
        <div className={`w-full p-4 ${!showSearchOptions && 'bg-[#FFF7E6]'}`}>
          {/* Header */}
          {showSearchOptions && (
            <div className='flex items-center mb-6 text-accent'>
              <button
                className='text-coral-500 flex items-center'
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon className='w-5 h-5 mr-2' />
                <span>Back</span>
              </button>
            </div>
          )}

          {/* Search Form */}

          <div className='flex justify-center items-center w-full flex-col text-sm'>
            {showSearchOptions && (
              <Typography
                fontSize='22px'
                marginBottom='12px'
                color='#4A403A'
                variant='body2'
                className='self-start'
              >
                Search item
              </Typography>
            )}

            {/* Search Input */}
            <div className='relative w-full'>
              <input
                type='text'
                placeholder='Search lost items'
                autoFocus
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
                onFocus={() => {
                  setShowSearchOptions(true);
                  setShowProductListing(false);
                }}
                className='w-full custom-placeholder p-3 rounded-lg bg-[#FFF7E6] focus:outline-none shadow-[0_0_12px_rgba(0,0,0,0.08)]'
              />
              {!showSearchOptions && (
                <button
                  onClick={() => setShowProductListing(true)}
                  className='absolute h-8 w-8 bg-[#FF6F61] rounded-full right-1 top-1/2 -translate-y-1/2 text-coral-500 flex justify-center items-center text-sm'
                >
                  <SearchIcon
                    className='w-4 h-4 text-[#FFF7E6]'
                    sx={{ fontSize: '18px' }}
                  />
                </button>
              )}
            </div>

            {showSearchOptions && (
              <Fragment>
                {/* Location Input */}
                <div className='relative my-2 w-full'>
                  <input
                    type='text'
                    readOnly
                    placeholder='Search city, area or locality'
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className='w-full custom-placeholder p-3 rounded-lg bg-[#FFF7E6] focus:outline-none'
                  />
                  <button className='absolute right-3 top-1/2 -translate-y-1/2 text-coral-500 flex items-center text-sm'>
                    <span
                      className='text-accent me-2'
                      onClick={handleUseCurrentLocation}
                    >
                      Use Current location
                    </span>
                    <MyLocation
                      onClick={() => openDialog()}
                      className='w-4 h-4 ml-1 text-accent'
                      sx={{ fontSize: '16px' }}
                    />
                  </button>
                </div>

                {/* Category Dropdown */}
                <div className='relative w-full'>
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className='w-full p-3 rounded-lg bg-[#FFF7E6] text-[#776E65] text-left flex items-center justify-between'
                  >
                    <span>{selectedCategory.name}</span>
                    <KeyboardArrowDownIcon
                      className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                    />
                  </button>

                  {isDropdownOpen && (
                    <ul className='absolute w-full mt-1 rounded-lg shadow-lg'>
                      {categories.map((category) => (
                        <li
                          key={category.id}
                          onClick={() => {
                            setSelectedCategory(category);
                            setIsDropdownOpen(false);
                          }}
                          className='w-full p-3 text-left hover:!bg-[#FFE0B5]'
                        >
                          {category.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* Search Button */}
                {!isDropdownOpen && (
                  <Button
                    buttonStyle={{
                      marginTop: '24px',
                    }}
                    onClick={handleSearch}
                    className='p-3 rounded-lg self-end flex items-center justify-center space-x-2 hover:bg-coral-600 transition-colors'
                  >
                    <Search className='w-5 h-5' />
                    <span>Search</span>
                  </Button>
                )}
              </Fragment>
            )}

            <Dialog
              open={state.isDialogOpen}
              title=''
              content={<GoogleMap onLocationSelect={handleLocationSelect} />}
              onClose={closeDialog}
            />
          </div>
        </div>
      </div>

      {/*Item Listing*/}
      {showProductListing && <SearchItems />}
    </Fragment>
  );
};
