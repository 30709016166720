import React, { Fragment, useState } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, Pagination, Typography, useMediaQuery } from '@mui/material';
import { CustomToggle, ProductListItem } from '../../../components';
import { Search } from '@mui/icons-material';
import { iPhoneImg } from '../../../assets';
import ViewListIcon from '@mui/icons-material/ViewListRounded';
import GridViewIcon from '@mui/icons-material/GridViewRounded';
import ArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useDispatch, useSelector } from 'react-redux';
import { SET_VIEW } from '../../../redux/actions/my-items';
import ProductCard from '../../../components/Layouts/ProductCard';
import { useNavigate } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

interface LostItemsHeaderProps {
  selectedTab: string;
  itemCount: number;
  onReportClick: () => void;
  onViewChange: (view: string) => void;
}

export const ManageMyItems = () => {
  const [selected, setSelected] = useState('Lost items');
  const [viewType, setViewType] = useState('Grid');
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');


  const LostItemsHeader: React.FC<LostItemsHeaderProps> = ({ itemCount, selectedTab, onReportClick, onViewChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  
    const dispatch = useDispatch();
  
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const handleViewChange = (newView: string) => {
      setViewType(newView);
      dispatch({ type: SET_VIEW, payload: newView });
      onViewChange(newView);
      handleMenuClose();
    };
  
    return (
      <Fragment>
        <Box className='w-full flex items-center justify-between py-8 pb-2 min-w-16'>
          {/* Title and Count */}
          <Box className='flex items-center space-x-4'>
            <Typography
              variant='h6'
              className='text-2xl font-semibold'
            >
              {selectedTab}
            </Typography>
          </Box>
  
          {/* View Options and Report Button */}
          <Box className='flex items-center'>
            {/* View Button with Dropdown */}
            <Box className='hidden md:flex space-x-2'>
              <span className='text-secondary-200 text-sm'>{itemCount} lost items</span>
              <IconButton
                onClick={handleMenuClick}
                className='border border-blue-500'
                size='small'
                disableRipple
                sx={{
                  height: 18,
                  '& .MuiSvgIcon-root': {
                    fontSize: 14,
                  },
                }}
              >
                {viewType === 'Grid' ? <GridViewIcon /> : <ViewListIcon />}
                <span className='ms-1 text-sm'>{viewType}</span>
                <ArrowDownRoundedIcon fontSize='small' />
              </IconButton>
  
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: 1,
                    backgroundColor: '#FFF7E6',
                    minWidth: 150,
                  },
                }}
              >
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#FFE0B5',
                    },
                  }}
                  onClick={() => handleViewChange('Grid')}
                >
                  <GridViewIcon fontSize='small' />
                  <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>Grid</span>
                </MenuItem>
                <MenuItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#FFE0B5',
                    },
                  }}
                  onClick={() => handleViewChange('List')}
                >
                  <ViewListIcon fontSize='small' />
                  <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>List</span>
                </MenuItem>
              </Menu>
            </Box>
            {/* Report Lost Item Button */}
            <Button
              variant='contained'
              size='small'
              sx={{
                background: '#FF6F61',
              }}
              onClick={onReportClick}
            >
              Report Lost Item
            </Button>
          </Box>
        </Box>
        <Box className='block md:hidden space-x-2 ms-auto'>
          <span className='text-secondary-200 text-sm'>{itemCount} lost items</span>
          <IconButton
            onClick={handleMenuClick}
            className='border border-blue-500'
            size='small'
            disableRipple
            sx={{
              height: 18,
              '& .MuiSvgIcon-root': {
                fontSize: 14,
              },
            }}
          >
            {viewType=== 'Grid' ? <GridViewIcon /> : <ViewListIcon />}
            <span className='ms-1 text-sm'>{viewType}</span>
            <ArrowDownRoundedIcon fontSize='small' />
          </IconButton>
  
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: 1,
                backgroundColor: '#FFF7E6',
                minWidth: 150,
              },
            }}
          >
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#FFE0B5',
                },
              }}
              onClick={() => handleViewChange('Grid')}
            >
              <GridViewIcon fontSize='small' />
              <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>Grid</span>
            </MenuItem>
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: '#FFE0B5',
                },
              }}
              onClick={() => handleViewChange('List')}
            >
              <ViewListIcon fontSize='small' />
              <span style={{ marginLeft: 8, marginTop: 4, fontSize: '0.875rem' }}>List</span>
            </MenuItem>
          </Menu>
        </Box>
      </Fragment>
    );
  };

  const products = [
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'Iphone 6',
      description: 'New Apple iPhone 16 Pro, 256GB.',
      location: 'Mohali',
      date: '10 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'Samsung Galaxy',
      description: 'Samsung Galaxy S21, 128GB.',
      location: 'Chandigarh',
      date: '12 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Accessory',
      itemName: 'Black Leather Wallet',
      description: "Wallet with driver's license and cards.",
      location: 'Downtown Café, 5th Avenue',
      date: '13 Oct 2024',
      type: 'Public',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Accessory',
      itemName: 'Ray-Ban Sunglasses',
      description: 'Classic Ray-Ban sunglasses.',
      location: 'Park Mall',
      date: '15 Oct 2024',
      type: 'Public',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'AirPods Pro',
      description: 'Apple AirPods Pro, with case.',
      location: 'City Center',
      date: '17 Oct 2024',
      type: 'Private',
    },
    {
      image: iPhoneImg,
      itemCategory: 'Electronic',
      itemName: 'AirPods Pro',
      description: 'Apple AirPods Pro, with case.',
      location: 'City Center',
      date: '17 Oct 2024',
      type: 'Private',
    },
  ];
  const handleToggle = (option: string) => {
    setSelected(option);
  };

  return (
    <Fragment>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <Typography
          textAlign='left'
          component='p'
          width={{
            xs:'90%', md:'70%'
          }}
          className='hidden md:block'
          marginTop='68px'
          fontSize='26px'
          letterSpacing='0.01px'
          color='#4A403A'
        >
          Manage My Items
        </Typography>

        <Box
         className='sm:w-10/12'
          marginTop='40px'
          width={{
            xs:'94%', md:'70%'
          }}
          bgcolor='#FFF7E6'
          display='flex'
          justifyContent='start'
          alignItems='center'
          borderRadius='8px'
          flexDirection='column'
          padding={{
            xs:'18px',md:'32px'
          }}
          boxShadow='0px 1px 1px #0000000D'
          overflow='scroll'
          minWidth='331px'
        >
          <CustomToggle
            selected={selected}
            options={[
              { key: 'Lost items', labelKey: 'lostItems', icon: <SentimentVeryDissatisfiedIcon /> },
              { key: 'Found items', labelKey: 'foundItems', icon: <ZoomInIcon /> },
              { key: 'Resolved items', labelKey: 'resolvedItems', icon: <Search /> },
              { key: 'Claim history', labelKey: 'claimHistory', icon: <Search /> },
            ]}
            handleToggle={handleToggle}
          />

          <LostItemsHeader
            selectedTab={selected}
            onViewChange={() => {}}
            itemCount={100}
            onReportClick={() => navigate('/report-lost-item')}
          />

          <Box
            display='flex'
            flexDirection='column'
            gap='16px'
            marginTop='16px'
            width='100%'
          >
            {viewType === 'List' ? (
              products.map((product, index) => (
                <ProductListItem
                  key={index}
                  title={product.itemName}
                  description={product.description}
                  location={product.location}
                  visibility={product.type}
                  date={product.date}
                  imageUrl={product.image}
                  onDelete={() => console.log('Delete clicked')}
                />
              ))
            ) : (
              <div
              className='grid place-items-center'
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fit, minmax(${isSmallScreen ? '140px' : '180px'}, 1fr))`,
                gap: '1rem'
              }}>
                {products.map((product, index) => (
                  <ProductCard
                    key={index}
                    image={product.image}
                    itemCategory={product.itemCategory}
                    itemName={product.itemName}
                    description={product.description}
                    location={product.location}
                    date={product.date}
                    type={product.type}
                    onEdit={() => {}}
                    onDelete={() => {}}
                  />
                ))}
              </div>
            )}
          </Box>

          {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
        </Box>
      </Box>
    </Fragment>
  );
};
