import React from 'react';
import { Container, Typography, Box, Select, MenuItem } from '@mui/material';

export const TermsAndConditions = () => {
  return (
    <div className='text-secondary-200 min-h-screen'>
      <Container
        maxWidth='lg'
        className='py-16'
      >
        {/* Header Section */}
        <div className='flex justify-center items-center'>
          <Typography
            variant='h4'
            className='font-semibold text-secondary-400'
          >
            Terms & Condition
          </Typography>
        </div>

        {/* Content Section */}
        <Box className='mt-8 space-y-8'>
          <Typography className='text-lg leading-relaxed'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, inventore, sit porro numquam perspiciatis
            sequi vero praesentium tempora consequatur, dolorum quo excepturi ullam nam veniam adipisci repellat sed
            libero dolore dignissimos dolor? Mollitia atque, laboriosam eum quo quos tenetur, pariatur veniam
            exercitationem eveniet ipsa beatae voluptatem distinctio, commodi repellat eaque.
          </Typography>

          {/* Mission */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Disclaimer
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam veritatis sunt in laborum suscipit
              consequatur, magni enim distinctio at recusandae odit placeat reiciendis tenetur libero iusto cupiditate
              aperiam provident ipsa!
            </Typography>
          </Box>

          {/* What We Offer */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Rights to access
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              1. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam quia fuga ad excepturi provident repellat
              velit ea itaque deleniti voluptate?
            </Typography>
            <Typography className='text-lg leading-relaxed mt-2'>
              2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, deleniti.
            </Typography>
            <Typography className='text-lg leading-relaxed mt-2'>
              3. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis ullam, iure repellendus, accusamus
              molestiae, debitis a blanditiis culpa cumque nulla esse saepe soluta impedit.
            </Typography>
            <Typography className='text-lg leading-relaxed mt-2'>
              4. Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa, cumque.{' '}
            </Typography>
          </Box>

          {/* Vision */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              Guidelines
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe laudantium, repellendus ab sunt eveniet
              accusamus ipsum ut aspernatur distinctio libero optio quis exercitationem minima eligendi sit quo ducimus
              velit doloremque voluptate eum molestiae magni ratione cum harum? Voluptate, beatae libero.
            </Typography>
          </Box>

          {/* Why Choose Lqeet */}
          <Box>
            <Typography
              variant='h5'
              className='font-semibold text-secondary-400 mb-4'
            >
              User responsibilities
            </Typography>
            <Typography className='text-lg leading-relaxed'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus nulla ratione eaque, consectetur libero
              dolore ipsum molestias aliquam omnis iusto dolorem quis velit voluptatem labore eligendi inventore
              reprehenderit voluptates facere!
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
