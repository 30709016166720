import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import ImageCarousel from '../../components/Layouts/ImageCarousel';
import { backgroundImage, getStaticTexts, iPhoneImg, ProfileIcon } from '../../assets';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import DirectionsWalkRoundedIcon from '@mui/icons-material/DirectionsWalkRounded';
import { Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { ClaimInstruction } from './claim-instruction';
import { useSelector } from 'react-redux';

export const ItemDetail = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const images = [
    { src: iPhoneImg, alt: 'img-1' },
    { src: iPhoneImg, alt: 'img-2' },
    { src: backgroundImage, alt: 'img-3' },
  ];

  return (
    <Box className='w-9/12 mt-24 mx-auto'>
      <Box
        sx={{
          display: 'grid',
          gap: '12px',
          gridTemplateColumns: { xs: '1fr', md: '2fr 2fr' },
        }}
      >
        {/* Box 1 */}
        <Box className='relative'>
          <Box
            sx={{
              minHeight: 450,
              marginBottom:'12px'
            }}
          >
            <ImageCarousel images={images} />
          </Box>
          <ClaimInstruction />
        </Box>

        {/* Box 2 */}
        <Box>
          <Box
            className='p-6 bg-[#FFF7E6] rounded-lg'
            sx={{
              order: { xs: 2, md: 1 },
            }}
          >
            <Typography
              className='text-secondary-200'
              fontSize='12px'
              component='p'
            >
              Electronics
            </Typography>

            <Typography
              component='p'
              className='text-secondary-400'
              fontSize='18px'
              fontWeight={500}
              variant='body1'
            >
              Black Leather Wallet
            </Typography>

            <Typography
              className='text-secondary-200'
              fontSize='14px'
              component='p'
              marginTop='12px'
              variant='body1'
            >
              Lost black leather wallet with zipper closure, containing ID card, credit cards, and cash.{' '}
            </Typography>

            <Box
              className='flex'
              marginTop='24px'
            >
              <LocationOnIcon className='me-1'  sx={{ color: '#776E65', fontSize:'18px' }} />
              <Typography
                className=' text-secondary-200'
                component='p'
                fontSize='14px'
              >
                Last seen near <span className='underline'>Downtown Café, 5th Avenue</span>
              </Typography>
            </Box>

            <Box
              className='flex'
              marginTop='8px'
            >
              <CorporateFareRoundedIcon
                className='me-1'
                sx={{ color: '#776E65', fontSize:'18px' }}
              />
              <Typography
                className=' text-secondary-200'
                component='p'
                fontSize='14px'
              >
                Found by <span className='underline text-accent'>Vertex Solution</span>
              </Typography>
            </Box>

            <Box
              className='flex'
              marginTop='8px'
            >
              <AccessTimeFilledIcon
                className='me-1'
                sx={{ color: '#776E65', fontSize:'18px' }}
              />
              <Typography
                className=' text-secondary-200'
                component='p'
                fontSize='14px'
              >
                Lost on October 15, 2024
              </Typography>
            </Box>

            <Box
              className='flex'
              marginTop='8px'
            >
              <PublicRoundedIcon
                className='me-1'
                sx={{ color: '#776E65', fontSize:'18px' }}
              />
              <Typography
                className=' text-secondary-200'
                component='p'
                fontSize='14px'
              >
                Public
              </Typography>
            </Box>

            <Button
              buttonStyle={{ background: '#FF6F61', textAlign: 'center', marginTop: '24px' }}
              onClick={() => navigate('/claim-item')}
            >
              {staticTexts.claimItem}
            </Button>

            <Box
              className='flex'
              bgcolor='#FFE0B5'
              borderRadius='8px'
              paddingY='8px'
              marginTop='12px'
            >
              <DirectionsWalkRoundedIcon className='me-1 text-secondary-100' />
              <Typography
                className=' text-secondary-200'
                component='p'
                fontSize='12px'
              >
                {staticTexts.claimItemNote}
              </Typography>
            </Box>

            <Typography
              className=' text-secondary-200'
              component='p'
              marginTop='12px'
              fontSize='12px'
            >
              {staticTexts.ownershipConfirmationNote}
            </Typography>

            <Box marginTop='24px'>
              <Typography variant='h6'>{staticTexts.disclaimer}</Typography>
              <Typography
                className=' text-secondary-200'
                component='p'
                marginTop='8px'
                fontSize='12px'
              >
                <span className='text-error'>*</span>
                {staticTexts.claimDisclaimer}
              </Typography>
            </Box>
          </Box>
          <Box
            marginTop='12px'
            className='bg-[#FFF7E6] rounded-lg flex justify-start w-full p-6'
          >
            <Avatar
              className='me-2'
              alt='img'
              src={ProfileIcon}
            />
            <Box>
              <Typography
                className='font-bold'
                component='p'
                fontSize='16px'
              >
                Lqeet User
              </Typography>
              <Typography
                fontSize='12px'
                component='p'
              >
                {staticTexts.founderFrom} United States
              </Typography>
              <Button buttonStyle={{ fontSize: '14px', marginTop: '14px', color: '#93897F', background: '#EDE6DB' }}>
                {staticTexts.chatWithFounder}
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Box 3 */}
        {/* <ClaimInstruction/> */}
      </Box>
    </Box>
  );
};
