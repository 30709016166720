import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { CustomButtonProps } from '../../interfaces';

/**
 * Author: Mayank
 * Date: 19 Nov 2024
 *
 * Summary:
 * This component is a wrapper around the Material-UI Button, designed to provide a customizable button with predefined styles
 * and additional customization options. It allows you to define the button's variant, color, custom styles, and event handling
 * logic while preserving the full functionality of the MUI Button.
 *
 * Description:
 * The `Button` component allows developers to pass various props to configure its appearance and behavior. It extends
 * the functionality of the base Material-UI Button by adding the ability to apply custom styles via the `buttonStyle` prop
 * and ensures the button behaves consistently across different use cases. The `variant` and `color` props default to
 * 'contained' and 'primary', respectively, but can be customized to fit the design requirements.
 *
 * Props:
 * - `variant`: The variant of the button (e.g., 'contained', 'outlined'). Defaults to 'contained'.
 * - `color`: The color of the button (e.g., 'primary', 'secondary'). Defaults to 'primary'.
 * - `onClick`: A function to call when the button is clicked.
 * - `children`: The content to be displayed inside the button, typically text or other components.
 * - `buttonStyle`: Custom styling for the button, passed to the `sx` prop for Material-UI styling.
 * - `props`: Additional props that will be passed down to the underlying MUI Button component, allowing for further customization.
 *
 * Returns:
 * A Material-UI Button component (`MuiButton`) with the specified `variant`, `color`, custom styles, and additional props.
 *
 * Usage Example:
 * ```tsx
 * <Button variant="outlined" color="secondary" onClick={handleClick}>
 *   Click Me
 * </Button>
 * ```
 */

export const Button: React.FC<CustomButtonProps> = ({
  variant = 'contained',
  color = 'primary',
  onClick,
  children,
  buttonStyle,
  ...props
}) => {
  return (
    <MuiButton
      variant={variant}
      color={color}
      onClick={onClick}
      sx={{ ...buttonStyle }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};
