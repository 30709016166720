import { Box, Typography } from '@mui/material';
import React, { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStaticTexts } from '../../../assets';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';

export const VerifySignupOTP = () => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = useMemo(() => getStaticTexts(language), []);
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box className='mx-auto w-full max-w-md px-4 py-8'>
        <Box className='text-center text-secondary-400 text-2xl'>
          <p className='font-medium'>Verify Number</p>
          <div className='text-secondary-200 text-sm mt-2 max-w-full text-center'>
          Verify your mobile number
          </div>
        </Box>

        <Box className='mt-6'>
          <div className='text-secondary-400 text-xs mt-6 text-left'>
          We’ll send an OTP to verify your mobile number.
          </div>
        </Box>

        <Box className='flex gap-4 mt-8 justify-center items-center'>
          <Button
            variant='outlined'
            className='px-6 py-2 border w-fit rounded-lg text-center text-sm'
            onClick={() => navigate(-1)}
            buttonStyle={{
              color: '#111111D8',
              border: '1px solid #C9C2B4',
            }}
          >
            Back
          </Button>

          <Button
            onClick={() => navigate('/auth/reset-password')}
            className='px-6 py-2 bg-[#FF6F61] text-white rounded-lg w-fit text-center text-sm'
          >
            Verify & Continue
          </Button>
        </Box>

        <p
          className='text-gray-500 text-xs text-center mt-8 cursor-pointer'
          onClick={() => {}}
        >
          {staticTexts.needHelp} <span className='underline'>{staticTexts.contactSupport}</span>
        </p>
      </Box>
    </Fragment>
  );
};
