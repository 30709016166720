import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Avatar, Button, Divider, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { ProfileIcon } from '../../../assets';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';

interface User {
  id: number;
  name: string;
  lastMessage: string;
  status: 'Online' | 'Offline';
}

interface ChatWindowProps {
  user: User;
  onBack: () => void;
  className?: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ user, onBack, className }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<{ text: string; sentBy: 'user' | 'bot'; image?: string }[]>([
    { text: 'Hi! Is this item yours?', sentBy: 'bot' },
    { text: 'Yes, I think it is.', sentBy: 'user' },
    { text: 'Could you describe any unique features or what’s inside?', sentBy: 'bot' },
    { text: 'Of course! Could you describe any unique features or what’s inside?', sentBy: 'user' },
    { text: 'Hi! Is this item yours?', sentBy: 'bot' },
    { text: 'Yes, I think it is.', sentBy: 'user' },
    { text: 'Could you describe any unique features or what’s inside?', sentBy: 'bot' },
    { text: 'Of course! Could you describe any unique features or what’s inside?', sentBy: 'user' },
    { text: 'Hi! Is this item yours?', sentBy: 'bot' },
    { text: 'Yes, I think it is.', sentBy: 'user' },
    { text: 'Could you describe any unique features or what’s inside?', sentBy: 'bot' },
    { text: 'Of course! Could you describe any unique features or what’s inside?', sentBy: 'user' },
  ]);
  const [image, setImage] = useState<File | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [messages]);

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();

    if (message.trim()) {
      setMessages((prev) => [...prev, { text: message, sentBy: 'user' }]);
      setMessage('');
    }

    if (image) {
      const imageUrl = URL.createObjectURL(image); // Convert image to URL
      setMessages((prev) => [...prev, { text: '', sentBy: 'user', image: imageUrl }]);
      setImage(null);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <div className={`${className} flex flex-col`}>
      {/* Header */}
      <AppBar
        position='static'
        sx={{
          backgroundColor: '#FFF7E6',
          boxShadow: 'none',
          color: '#4A403A',
          borderRadius: '8px',
        }}
      >
        <Toolbar>
          <IconButton
            className='md:!hidden sm:block'
            style={{ color: '#FF6F61' }}
            onClick={onBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Avatar
            className='me-3'
            src={ProfileIcon}
            alt='user-profile-img'
          />
          <Typography
            variant='h6'
            className='ml-2'
            fontSize='16px'
          >
            {user.name}
          </Typography>
        </Toolbar>
        <Divider />
      </AppBar>

      {/* Messages */}
      <div className='flex-1 p-4 overflow-y-auto'>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`mb-4 ${msg.sentBy === 'user' ? 'text-right' : ''}`}
          >
            <div className={`${msg.sentBy === 'user' ? 'bg-[#FFE0B5]' : 'bg-[#ECE4D5]'} p-2 rounded-lg inline-block`}>
              {msg.text}
              {msg.image && (
                <div className='mt-2'>
                  <img
                    src={msg.image}
                    alt='uploaded'
                    className='max-w-[150px] max-h-[150px] object-cover rounded-md'
                  />
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>

      {/* Input */}
      <div className='p-1 border rounded-lg m-2 border-[#ECE4D5]'>
        <form
          onSubmit={handleSendMessage}
          className='flex gap-2 items-center'
        >
          {/* Image Upload */}
          <input
            accept='image/*'
            id='upload-image'
            type='file'
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <label htmlFor='upload-image'>
            <IconButton
              style={{ color: '#776E65' }}
              component='span'
            >
              <AttachFileIcon />
            </IconButton>
          </label>
          {/* Text Input */}
          <input
            type='text'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder='Message here'
            className='w-full focus:outline-none bg-transparent'
          />

          {/* Send Button */}
          <Button
            variant='contained'
            type='submit'
            color='primary'
            className='p-1 rounded-lg max-h-9'
          >
            <SendIcon sx={{ color: '#FFF7E6', fontSize: '18px', marginInlineEnd: '4px' }} />
            Send
          </Button>
        </form>

        {/* Preview Uploaded Image */}
        {image && (
          <div className='mt-2 flex items-center gap-2'>
            <img
              src={URL.createObjectURL(image)}
              alt='Preview'
              className='w-16 h-16 object-cover rounded-md'
            />
            <Button
              size='small'
              variant='outlined'
              color='secondary'
              onClick={() => setImage(null)}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatWindow;
