import React, { useState } from 'react';
import ChatList from './chat-list';
import ChatWindow from './chat-window';
import { Box } from '@mui/material';

interface User {
  id: number;
  name: string;
  item: string;
  lastMessage: string;
  status: 'Online' | 'Offline';
  date: string;
}

const ChatScreen: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const users: User[] = [
    {
      id: 1,
      name: 'John Doe',
      item: 'Red Wallet',
      lastMessage: 'Glad you found it useful!',
      status: 'Offline',
      date: '03/10/2024',
    },
    {
      id: 2,
      name: 'Emma Stone',
      item: 'Red Wallet',
      lastMessage: "Let's arrange a pickup time.",
      status: 'Online',
      date: '03/10/2024',
    },
    {
      id: 3,
      name: 'David Lee',
      item: 'Red Wallet',
      lastMessage: 'Can you confirm the time?',
      status: 'Online',
      date: '03/10/2024',
    },
  ];

  return (
    <Box
      className='h-screen block md:flex justify-center'
      marginTop={{
        xs: '0px',
        md: '32px'
      }}
    >
      {/* Chat List */}
      <Box
        sx={{
          width: { xs: '100%', md: '75%' },
          height: { xs: '100%', md: '85%' },
        }}
        className='block md:flex gap-2 justify-between h-full md:h-4/5'
      >
        <Box
          sx={{
            width: { xs: '100%', md: '35%' },
          }}
          className={`${
            selectedUser ? 'hidden md:block' : 'block'
          } rounded-lg bg-[#FFF7E6] h-full w-full md:w-1/3 border-r border-gray-200`}
        >
          <ChatList
            users={users}
            onSelectUser={(user) => setSelectedUser(user)}
            className='h-full'
          />
        </Box>

        {/* Chat Window */}
        <Box
          sx={{
            width: { xs: '100%', md: '65%' },
            height: { xs: 'calc(100% - 50px)', md: '100%' },
          }}
          className={`${selectedUser ? 'block' : 'hidden md:block'} rounded-lg bg-[#FFF7E6]`}
        >
          {selectedUser ? (
            <ChatWindow
              user={selectedUser}
              onBack={() => setSelectedUser(null)}
              className='h-full'
            />
          ) : (
            <Box className='hidden md:flex items-center justify-center h-full'>
              <p className='text-gray-500'>Select a chat to start messaging</p>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatScreen;
