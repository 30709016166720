import React, { ReactNode } from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface CommonDialogProps {
  open: boolean;
  title?: string;
  content?: ReactNode;
  actions?: { label: string; onClick: () => void; color?: 'primary' | 'secondary' | 'error' }[];
  onClose: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  titlePadding?: string;
  contentPadding?: string;
  actionsPadding?: string;
}

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    maxWidth: '640px',
  },
}));

export const Dialog: React.FC<CommonDialogProps> = ({
  open,
  title,
  content,
  actions,
  onClose,
  maxWidth = 'sm',
  fullWidth = true,
  titlePadding = '16px',
  contentPadding = '8px',
  actionsPadding = '24px',
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {title && <DialogTitle style={{ padding: titlePadding }}>{title}</DialogTitle>}
      <IconButton
        aria-label='close'
        onClick={onClose}
        edge='end'
        style={{
          marginLeft: 'auto',
          position: 'absolute',
          right: '16px',
          top: '8px',
          backgroundColor: '#FFE0B5',
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent style={{ padding: contentPadding }}>{content}</DialogContent>
      {actions && (
        <DialogActions style={{ padding: actionsPadding }}>
          {actions.map((action, index) => (
            <Button
              key={index}
              onClick={action.onClick}
              color={action.color || 'primary'}
            >
              {action.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </StyledDialog>
  );
};
