import React, { useState } from 'react';
import { PasswordInput, Button } from '../../components';
import { useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStaticTexts } from '../../assets';

interface FormFields {
  newPassword: string;
  confirmPassword: string;
}
export const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>();

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const handleSave = () => {
    // Add save functionality
    console.log('New Password:', newPassword);
    console.log('Confirm Password:', confirmPassword);
    navigate('/home');
  };

  return (
    <div className='flex flex-col items-center p-8 min-h-screen mx-auto max-w-max'>
      {/* Title */}
      <Typography
        variant='body2'
        component='p'
        fontSize='26px'
        color='#4A403A'
        letterSpacing='0.01px'
      >
        {staticTexts.setNewPassword}
      </Typography>
      <Typography
        variant='body1'
        component='p'
        fontSize='16px'
        color='#655B53FA'
        className='text-secondary-200 text-sm mt-2'
        marginBottom='48px'
      >
        {staticTexts.createNewPasswordForYourAccount}
      </Typography>

      {/* New Password Input */}
      <PasswordInput
        name='password'
        label={staticTexts.newPassword}
        className='!mb-3'
        control={control}
        error={errors.newPassword}
        helperText={staticTexts.passwordHelperText}
        validationRules={{
          required: `${staticTexts.password} ${staticTexts.isRequired}`,
          minLength: {
            value: 6,
            message: staticTexts.passwordMustBeAtleast6char,
          },
        }}
      />

      {/* Confirm New Password Input */}
      <PasswordInput
        name='password'
        label={staticTexts.confirmNewPassword}
        control={control}
        error={errors.confirmPassword}
        helperText={staticTexts.makeSureBothPasswordMatch}
        validationRules={{
          required: `${staticTexts.confirmPassword} ${staticTexts.isRequired}`,
          minLength: {
            value: 6,
            message: staticTexts.passwordMustBeAtleast6char,
          },
        }}
      />

      {/* Action Buttons */}
      <div className='flex gap-4 mt-8'>
        <Button
          variant='outlined'
          buttonStyle={{
            border: '1px solid #C9C2B4',
            color: '#111111D8',
          }}
          className='px-6 py-2 rounded-md hover:bg-gray-100'
          onClick={()=>navigate(-1)}
        >
          {staticTexts.cancel}
        </Button>
        <Button onClick={handleSave}>{staticTexts.saveNewPassword}</Button>
      </div>

      {/* Footer */}
      <Typography
        variant='body1'
        fontSize='12px'
        color='#776E65'
        marginTop='40px'
        className='text-center cursor-pointer'
        onClick={() => navigate('/auth/signup')}
      >
        {staticTexts.needHelp} <span className='underline'>{staticTexts.contactSupport}</span>
      </Typography>
    </div>
  );
};
