import { createTheme } from '@mui/material';

const theme = createTheme({
  direction:'rtl',
  typography: {
    fontFamily: 'Roboto, sans-serif',
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: '#FF6F61',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#C9C2B4',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: 'none',
          fontWeight: 'normal',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // height: '48px',
          input: {
            padding: '14px',
            color: '#4A403A',
          },
          '&.MuiOutlinedInput-root': {
            fieldset: {
              border: '1px solid #776E65',
            },
            '&:hover fieldset': {
              borderColor: '#4A403A',
            },
            '&.Mui-focused fieldset': {
              border: '1px solid #4A403A',
            },
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.MuiStack-root': {
            paddingTop: 0,
          },

          '&.MuiTextField-root': {
            width: '100%',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#FFE0B5',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: '#FFE0B5',
          },
        },
        select: {
          '& .MuiMenuItem-root:hover': {
            backgroundColor: '#FFE0B5',
          }
        },
      },
    }, 
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0 8px',
          '&:last-child': {
            paddingBottom: '0px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#4A403A',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF7E6',
        },
        option: {
          '&:hover': {
            backgroundColor: '#FFE0B5 !important',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFF7E6',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPickersPopper-paper': {
            backgroundColor: '#FFF7E6',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-root': {
            backgroundColor: '#FFF7E6',
            boxShadow:'none'
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#FF6F61',
          },
        },
      },
    },
  },
});

export default theme;
