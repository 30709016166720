import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SxProps, TextFieldProps } from '@mui/material';
import { Controller, Control, FieldError, RegisterOptions } from 'react-hook-form';

interface DateInputProps {
  control?: Control<any>;
  name: string;
  label?: string;
  className?: string;
  defaultValue?: Dayjs | null;
  error?: FieldError;
  helperText?: string;
  onChange?: (newValue: Dayjs | null) => void;
  validationRules?: RegisterOptions;
  dateInputStyles?: SxProps
}

export const DateInput: React.FC<DateInputProps> = ({
  control,
  name,
  label = 'Date',
  defaultValue = null,
  error,
  helperText = '',
  onChange,
  validationRules = {},
  className,
  dateInputStyles
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <DatePicker
            className={`${className} overflow-hidden`}
            value={field.value || defaultValue}
            onChange={(newValue) => {
              field.onChange(newValue);
              if (onChange) onChange(newValue);
            }}
            format='DD/MM/YYYY'
            sx={{
              ...dateInputStyles
            }}
            slotProps={{
              textField: {
                label,
                error: !!error,
                helperText: error?.message || helperText,
                ...field,
              } as TextFieldProps,
              day: {
                sx: {
                  '&.Mui-selected': {
                    backgroundColor: '#FF6F61 !important',
                    color: '#FFF7E6',
                  },
                  '&.MuiPickersDay-today': {
                    border: '1px solid #FF6F61 !important',
                    background: '#FFF7E6',
                  },
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
